Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DailyScheduleNotifications";
exports.labelBodyText = "DailyScheduleNotifications Body";

exports.btnExampleTitle = "CLICK ME";
exports.logInApi =  "/bx_block_login/logins";
exports.signUpApi =  "/account_block/accounts";
exports.getEmailAPiEndPoint = "/bx_block_dailyschedulenotifications/dailyschedulenotifications/user_email_collection";
exports.sendNotificationAPiEndPoint = "/bx_block_dailyschedulenotifications/dailyschedulenotifications";
exports.getAllNotificationAPiEndPoint = "/bx_block_dailyschedulenotifications/dailyschedulenotifications";
//static text
exports.goBack = "Go back"
exports.confirmPassword = "Confirm Password"
exports.user = "User"
exports.business = "Business"
exports.title = "RoscoPerlini"
exports.email = "Email"
exports.signUp = "Sign up"
exports.signIn = "Log in"
exports.sendNotification = "Send Notification"
exports.emptyText = "No Email Found"
exports.send = "Schedule"
exports.description = "Description"
exports.header = "Header"
exports.selectEmail = "Select email"
exports.message = "message"
exports.emptyNotification = "There is no push notification"
exports.hello = "Hello,";
exports.courses = "Courses";
exports.LearningPath = 'Learning Path';
exports.AssignedCourses = 'Assigned Courses';
exports.ViewAll = 'View All';
exports.signUpApiJs =  "account_block/accounts";
exports.logInApiJs =  "bx_block_login/logins";
exports.getEmailAPiEndPointJs = "bx_block_dailyschedulenotifications/dailyschedulenotifications/user_email_collection";
exports.sendNotificationAPiEndPointJs="bx_block_dailyschedulenotifications/dailyschedulenotifications";
exports.getAllNotificationAPiEndPointJs = "bx_block_dailyschedulenotifications/dailyschedulenotifications";
// Customizable Area End