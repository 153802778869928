import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}
export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox"
  | "dob";
export type FieldValue = string | number | boolean | Date | null | Moment;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
  attributes?: { [key: string]: FieldValue };
}

interface IProfileDataWeb {
  attributes?: { [key: string]: FieldValue };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Moment } from "moment";
import { toast } from "react-toastify";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  anchorEl: null | HTMLElement;
  menuOpen: boolean;
  profilePictureUpdate: string;
  uploadPreviewImage: string | ArrayBuffer | null;
  fieldModal: boolean;
  cancelModal: boolean;
  deleteModal: boolean;
  certificateModal: boolean;
  sectionModal: boolean;
  enableBasicInfoSection: boolean;
  enableCertificateSection: boolean;
  enableCustomSection: boolean;
  enableCustomSectionId: number;
  certificate: { [key: string]: FieldValue };
  allCertificates: { attributes: { [key: string]: FieldValue } }[];
  enableEditCertificate: boolean;
  enableEditField: boolean;
  enableEditSection: boolean;
  field_educator: { [key: string]: FieldValue };
  all_field_educator: { attributes: { [key: string]: FieldValue } }[];
  section: { [key: string]: FieldValue };
  allSection: { attributes: { [key: string]: FieldValue } }[];
  enableSectionDetails: { [key: string]: FieldValue };
  field_type: { [key: string]: FieldValue };
  all_field_type: { [key: string]: FieldValue }[];
  editProfile: boolean;
  allStudentsProfile: { attributes: { [key: string]: FieldValue } }[];
  allStudentModal: boolean;
  openEmployeeEditModal: boolean;
  selectedEmployeeDetail: { [key: string]: FieldValue };
  filterValue: string;
  webCertificateError: boolean;
  confirmModal: boolean;
  loading: boolean;
  allCertificateModal: boolean;
  allGroupName: { [key: string]: FieldValue }[];
  deleteId: FieldValue;
  deleteType: string;
  deleteModalSub: string;
  enableDarkMode: boolean;
  sideMenuOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  updateProfileImageCallId: string = "";
  deleteProfileImageCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  upload: HTMLInputElement | null = null;
  addCertificateProfileCallId: string = "";
  getCertificateProfileCallId: string = "";
  deleteCertificateProfileCallId: string = "";
  EditCertificateProfileCallId: string = "";
  addCustomFieldCallId: string = "";
  getCustomFieldCallId: string = "";
  deleteCustomFieldCallId: string = "";
  editCustomFieldCallId: string = "";
  addSectionCallId: string = "";
  getSectionCallId: string = "";
  deleteSectionCallId: string = "";
  editSectionCallId: string = "";
  addCustomSectionFieldCallId: string = "";
  getCustomSectionFieldCallId: string = "";
  deleteCustomSectionFieldCallId: string = "";
  editCustomSectionFieldCallId: string = "";
  getAllProfileCallId: string = "";
  updateSingleEmployeeCallId: string = "";
  getGroupNameCallId: string = '';
  getFilterGroupNameCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      anchorEl: null,
      menuOpen: false,
      profilePictureUpdate: '',
      uploadPreviewImage: '',
      fieldModal: false,
      cancelModal: false,
      deleteModal: false,
      certificateModal: false,
      sectionModal: false,
      enableBasicInfoSection: true,
      enableCertificateSection: false,
      enableCustomSection: false,
      enableCustomSectionId: 0,
      certificate: {},
      allCertificates: [],
      enableEditCertificate: false,
      enableEditField: false,
      enableEditSection: false,
      field_educator: {},
      all_field_educator: [],
      section: {},
      allSection: [],
      enableSectionDetails: {},
      field_type: {},
      all_field_type: [],
      editProfile: false,
      allStudentsProfile: [],
      allStudentModal: false,
      openEmployeeEditModal: false,
      selectedEmployeeDetail: {},
      filterValue: "",
      webCertificateError: false,
      confirmModal: false,
      loading: false,
      allCertificateModal: false,
      allGroupName: [],
      deleteId: "",
      deleteType: "",
      deleteModalSub: "",
      enableDarkMode: false,
      sideMenuOpen: false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.checkTheme();
    window.addEventListener('storage', (event) => {
      if (event.key === 'darkMode') {
        this.checkTheme()
      }
    })

    let menuState:any= localStorage.getItem('open');
    if(menuState!=null){
     this.setState({sideMenuOpen:JSON.parse(menuState)})
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  checkTheme = async () => {
    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ enableDarkMode: true })
    }
    else {
      this.setState({ enableDarkMode: false })
    }
  }

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile }, loading: false });
    if (!data) return;
    if (!data.attributes)
      return this.setState({ currentProfile: { ...this.state.profile }, loading: false });

    const newProfile = data.attributes;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile }, loading: false });
    if (data.attributes.role_id === "supervisor/manager") {
      this.getAllStudentProfile();
      this.getGroupName();
    }
    if (data.attributes.role_id === 'educator') {
      this.getCertificates();
      this.getField();
      this.getSection();
    }
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      toast.success("Profile Updated Successfully");
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false,
        editProfile: false,
        loading: false
      }, () => this.getCustomizableProfile());
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };
  checkCertificateResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.addCertificateProfileCallId || apiRequestCallId === this.EditCertificateProfileCallId) {
      this.setState({
        loading: false,
        certificateModal: false,
        certificate: { ...this.state.certificate, ["certificate_organisation"]: "", ["website"]: "", ["certificate_type"]: "", ["date_received"]: "", ["description"]: "" },
        webCertificateError: false,
      },
        () => this.getCertificates());
      toast.success(responseJson.meta.message);
    } else if (apiRequestCallId === this.getCertificateProfileCallId) {
      this.setState({
        allCertificates: responseJson.data
      })
    } else if (apiRequestCallId === this.deleteCertificateProfileCallId) {
      this.getCertificates();
      toast.success(responseJson.message)
    }
  };
  checkEducatorFieldResponses = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (apiRequestCallId === this.addCustomFieldCallId || apiRequestCallId === this.editCustomFieldCallId) {
      this.setState({
        loading: false,
        fieldModal: false,
        field_type: { ...this.state.field_type, ["name"]: "", ["description"]: "" },
        field_educator: { ...this.state.field_educator, ["name"]: "", ["description"]: "" }
      },
        () => this.getField());
      toast.success(responseJson.meta.message);
    } else if (apiRequestCallId === this.getCustomFieldCallId) {
      this.setState({
        all_field_educator: responseJson.data
      })
    } else if (apiRequestCallId === this.deleteCustomFieldCallId) {
      this.getField();
      toast.success(responseJson.message)
    }
  };
  checkSectionResponses = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (apiRequestCallId === this.addSectionCallId) {
      this.setState({
        loading: false,
        sectionModal: false,
        section: { ...this.state.section, ['name']: "" }
      }, () => this.getSection());
      toast.success(responseJson.meta.message);
    } else if (apiRequestCallId === this.getSectionCallId) {
      this.setState({ allSection: responseJson.data })
    } else if (apiRequestCallId === this.deleteSectionCallId) {
      this.setState({ loading: false, enableBasicInfoSection: true, enableCustomSection: false })
      this.getSection();
      toast.success(responseJson.message)
    } else if (apiRequestCallId === this.editSectionCallId) {
      toast.success(responseJson.meta.message);
      this.setState({ sectionModal: false }, () => this.getSection());
    }
  };
  checkCustomSectionFiledResponses = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (apiRequestCallId === this.addCustomSectionFieldCallId || apiRequestCallId === this.editCustomSectionFieldCallId) {
      this.setState({ fieldModal: false, field_type: { ...this.state.field_type, ["name"]: "", ["description"]: "" } }, () => this.getCustomSectionField(Number(this.state.enableSectionDetails.id)));
      toast.success(responseJson.meta.message);
    } else if (apiRequestCallId === this.getCustomSectionFieldCallId) {
      this.setState({ all_field_type: responseJson })
    } else if (apiRequestCallId === this.deleteCustomSectionFieldCallId) {
      this.getCustomSectionField(Number(this.state.enableSectionDetails.id));
      toast.success(responseJson.message)
    }
  };
  checkImageResponses = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (apiRequestCallId === this.updateProfileImageCallId) {
      this.setState({ anchorEl: null, loading: false }, () => this.getCustomizableProfile());
      toast.success(responseJson.message);
    } else if (apiRequestCallId === this.deleteProfileImageCallId) {
      toast.success(responseJson.message);
      this.setState({ anchorEl: null, loading: false }, () => this.getCustomizableProfile());
    }
  };
  checkAllProfileResponses = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (apiRequestCallId === this.getAllProfileCallId) {
      this.setState({ allStudentsProfile: responseJson.data });
    }
    else if (apiRequestCallId === this.getGroupNameCallId) {
      this.setState({ allGroupName: responseJson.group })
    }
    else if (apiRequestCallId === this.getFilterGroupNameCallId) {
      this.setState({ allStudentsProfile: responseJson.data, loading: false })
    }
    else if (apiRequestCallId === this.updateSingleEmployeeCallId) {
      if (responseJson.error) {
        toast.error(responseJson.error)
      } else {
        this.setState({ allStudentModal: false, confirmModal: true }, () => this.getAllStudentProfile());
      }
    }
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        if (responseJson.errors) {
          if (responseJson.errors[0].token) {
            this.showAlert("error", responseJson.errors[0].token);
            this.goToLogin()
          }
          else if (responseJson.errors[0] == "Website must start with http:// or www") {
            this.setState({ webCertificateError: true })
          }
          else if (responseJson.errors[0] == "Full phone number has already been taken") {
            this.setState({ loading: false })
            toast.error(responseJson.errors[0])
          }
          else {
            this.setState({ loading: false })
            toast.error(responseJson.errors);
          }
          return
        };
        this.checkOtherResponses(message);
        this.checkProfileAccount(message);
        this.checkCertificateResponses(message);
        this.checkEducatorFieldResponses(message);
        this.checkSectionResponses(message);
        this.checkCustomSectionFiledResponses(message);
        this.checkImageResponses(message);
        this.checkAllProfileResponses(message);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null | Moment,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  changeCertificateValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
  ) => {
    this.setState({ certificate: { ...this.state.certificate, [valueKey]: value } });
  };

  changeSelectedEmployeeValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
  ) => {
    this.setState({ selectedEmployeeDetail: { ...this.state.selectedEmployeeDetail, [valueKey]: value } });
  };

  changeSectionValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
  ) => {
    this.setState({ section: { ...this.state.section, [valueKey]: value } });
  };

  changeFieldValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
  ) => {
    if (this.state.enableCustomSection) {
      this.setState({ field_type: { ...this.state.field_type, [valueKey]: value } });
    }
    else {
      this.setState({ field_educator: { ...this.state.field_educator, [valueKey]: value } });
    }
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
    if (field.field_type === "text" && (!/^[a-zA-Z ]*$/.test(event.key))) {
      event.preventDefault();
    }
    if (field.field_type === "number" && (!/\d/.test(event.key))) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = async () => {
    this.setState({ loading: true })
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    this.setState({
      editProfile: false,
      saveEnabled: false,
      cancelEnabled: false
    });
  };

  updateCustomizableProfile = async () => {
    if (!this.state.profile['first_name']) {
      toast.error("Please Enter First Name")
      return
    }
    if (!this.state.profile['last_name']) {
      toast.error("Please Enter Last Name")
      return
    }
    if (this.state.profile['full_phone_number']?.toString().length! < 11 || this.state.profile['full_phone_number']?.toString().length! > 15) {
      toast.error("Please enter valid phone number with country code");
      return
    }
    this.setState({ loading: true })
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAttributes = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAttributesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFields
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  imgFileHandler = (event: any) => {
    const file = event.target.files[0];
    this.setState({
      menuOpen: false,
      profilePictureUpdate: file,
      loading: true
    }, () => { this.updateProfileImage() }
    )
    if (file) {
      const reader: FileReader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          this.setState({ uploadPreviewImage: reader.result });
        }
      };
      reader.readAsDataURL(file);
    }
  }

  updateProfileImage = async () => {
    const token = await getStorageData("authToken");
    const header = {
      token: token
    };

    const formData = new FormData();
    formData.append('image', this.state.profilePictureUpdate);

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateProfileImageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileImageEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  removeImage = async () => {
    this.setState({ uploadPreviewImage: '', profilePictureUpdate: '', menuOpen: false, anchorEl: null, loading: true })
    const token = await getStorageData("authToken");
    const header = {
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteProfileImageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteProfileImageEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openFieldModal = () => {
    this.setState({ fieldModal: true, enableEditField: false })
  }

  openCancelModal = () => {
    this.setState({ cancelModal: true, enableEditSection: false })
  }

  openDeleteModal = (docId: FieldValue, deleteType: string, suHeading: string) => {
    this.setState({ deleteModal: true, deleteId: docId, deleteType: deleteType, deleteModalSub: suHeading })
  }

  closeCancelModal = () => {
    this.setState({ cancelModal: false })
  }

  closeDeleteModal = () => {
    this.setState({ deleteModal: false })
  }

  closeBothModalCall = () => {
    this.setState({ deleteModal: false })
    if(this.state.enableCustomSection && this.state.deleteType === 'field') {
      this.deleteCustomSectionField(this.state.deleteId)
    }
    if (this.state.deleteType === 'certificate') {
      this.deleteCertificate(this.state.deleteId)
    }
    if (!this.state.enableCustomSection && this.state.deleteType === 'field') {
      this.deleteField(this.state.deleteId)
    }
    if (this.state.deleteType === 'section') {
      this.deleteSection(this.state.deleteId)
    }
  }

  closeBothModal = () => {
    this.setState({
      cancelModal: false, fieldModal: false, certificateModal: false, sectionModal: false, confirmModal: false, openEmployeeEditModal: false,
      field_type: { ...this.state.field_type, ["name"]: "", ["description"]: "" },
      field_educator: { ...this.state.field_educator, ["name"]: "", ["description"]: "" },
      certificate: { ...this.state.certificate, ["certificate_organisation"]: "", ["website"]: "", ["certificate_type"]: "", ["date_received"]: "", ["description"]: "" },
      webCertificateError: false,
      section: { ...this.state.section, ['name']: "" }
    })
  }

  enableCertificateModal = () => {
    this.setState({ certificateModal: true, enableEditCertificate: false })
  }

  enableSectionModal = () => {
    this.setState({ sectionModal: true })
  }

  enableBasicInfo = () => {
    this.setState({ enableBasicInfoSection: true, enableCertificateSection: false, enableCustomSection: false })
  }

  enableCertificateSection = () => {
    this.setState({ enableBasicInfoSection: false, enableCertificateSection: true, enableCustomSection: false })
  }

  enableCustomSection = (element: { [key: string]: FieldValue }) => {
    this.setState({ enableBasicInfoSection: false, enableCertificateSection: false, enableCustomSection: true, enableSectionDetails: element, enableCustomSectionId: Number(element.id) },
      () => this.getCustomSectionField(Number(element.id)))
  }

  apiCall = async (data: { [key: string]: FieldValue }) => {
    const { method, endPoint, body, type, contentType } = data;
    let apiBody = body;
    if (type === '') {
      apiBody = JSON.stringify(body);
    }
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.baseURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  addCertificate = async () => {
    if (!this.state.certificate["certificate_organisation"]) {
      toast.error("Please enter organization", { containerId: 'A' })
      return
    }
    if (!this.state.certificate["website"]) {
      toast.error("Please enter website", { containerId: 'A' })
      return
    }
    if (!this.state.certificate["certificate_type"]) {
      toast.error("Please enter certificate type", { containerId: 'A' })
      return
    }
    if (!this.state.certificate["date_received"] || this.state.certificate["date_received"].toLocaleString.length < 4) {
      toast.error("Please enter correct received year", { containerId: 'A' })
      return
    }
    this.setState({ loading: true })
    this.addCertificateProfileCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.addCertificateEndPoint,
      body: JSON.stringify(this.state.certificate)
    });
  }

  getCertificates = async () => {
    this.getCertificateProfileCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.addCertificateEndPoint
    });
  }

  deleteCertificate = async (userId: FieldValue) => {
    this.deleteCertificateProfileCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpDeleteMethod,
      endPoint: `${configJSON.addCertificateEndPoint}/${userId}`
    });
  }

  EditCertificate = (item: { [key: string]: FieldValue }) => {
    let certificate = Object.assign(this.state.certificate, item)
    this.setState({ certificate, certificateModal: true, enableEditCertificate: true })
  }

  editAddedCertificate = async (userId: number) => {
    if (!this.state.certificate["website"] || !this.state.certificate["certificate_organisation"] || !this.state.certificate["date_received"] || !this.state.certificate["certificate_type"]) {
      toast.error("All field is required", { containerId: 'A' })
      return
    }

    this.EditCertificateProfileCallId = await this.apiCall({
      endPoint: `${configJSON.addCertificateEndPoint}/${userId}`,
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpPutMethod,
      body: JSON.stringify(this.state.certificate)
    });
  }

  addField = async () => {
    this.setState({ loading: true })
    this.addCustomFieldCallId = await this.apiCall({
      method: configJSON.httpPostMethod,
      contentType: configJSON.searchApiContentType,
      endPoint: configJSON.addFieldEndPoint,
      body: JSON.stringify(this.state.field_educator)
    });
  }

  getField = async () => {
    this.getCustomFieldCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.addFieldEndPoint,
    });
  }

  editAddedField = async (userId: FieldValue) => {
    this.editCustomFieldCallId = await this.apiCall({
      method: configJSON.httpPutMethod,
      endPoint: `${configJSON.addFieldEndPoint}/${userId}`,
      contentType: configJSON.searchApiContentType,
      body: JSON.stringify(this.state.field_educator)
    });
  }

  editField = (item: { [key: string]: FieldValue } | undefined) => {
    let field_educator = Object.assign(this.state.field_educator, item)
    let field_type = Object.assign(this.state.field_type, item)
    if (this.state.enableCustomSection) {
      this.setState({ field_educator, fieldModal: true, enableEditField: true })
    } else {
      this.setState({ field_type, fieldModal: true, enableEditField: true })
    }
  }

  deleteField = async (userId: FieldValue) => {
    this.deleteCustomFieldCallId = await this.apiCall({
      method: configJSON.httpDeleteMethod,
      contentType: configJSON.searchApiContentType,
      endPoint: `${configJSON.addFieldEndPoint}/${userId}`,
    });
  }

  addSection = async () => {
    this.setState({ loading: true })
    if (!this.state.section["name"]) {
      toast.error("Please enter section name", { containerId: "B" })
      this.setState({ loading: false })
      return
    }
    this.addSectionCallId = await this.apiCall({
      method: configJSON.httpPostMethod,
      contentType: configJSON.searchApiContentType,
      endPoint: configJSON.addSectionEndPoint,
      body: JSON.stringify(this.state.section)
    });
  }

  getSection = async () => {
    this.getSectionCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.addSectionEndPoint,
    });
  }

  editAddedSection = async (userId: FieldValue) => {
    if (!this.state.section["name"]) {
      toast.error("Section name is required", { containerId: "B" })
      return
    }
    this.editSectionCallId = await this.apiCall({
      method: configJSON.httpPutMethod,
      endPoint: `${configJSON.addSectionEndPoint}/${userId}`,
      contentType: configJSON.searchApiContentType,
      body: JSON.stringify(this.state.section)
    });
  }

  editSection = (item: { [key: string]: FieldValue } | undefined) => {
    let section = Object.assign(this.state.section, item)
    this.setState({ section, sectionModal: true, enableEditSection: true })
  }

  deleteSection = async (userId: FieldValue) => {
    this.setState({ loading: true })
    this.deleteSectionCallId = await this.apiCall({
      method: configJSON.httpDeleteMethod,
      contentType: configJSON.searchApiContentType,
      endPoint: `${configJSON.addSectionEndPoint}/${userId}`,
    });
  }

  addCustomSectionField = async (userId: number) => {
    this.addCustomSectionFieldCallId = await this.apiCall({
      method: configJSON.httpPostMethod,
      contentType: configJSON.searchApiContentType,
      endPoint: `${configJSON.addSectionEndPoint}/${userId}/field_types`,
      body: JSON.stringify(this.state.field_type)
    });
  }

  getCustomSectionField = async (userId: number) => {
    this.getCustomSectionFieldCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.addSectionEndPoint}/${this.state.enableSectionDetails.id}/field_types`,
    });
  }

  editAddedCustomSectionField = async (userId: FieldValue) => {
    this.editCustomSectionFieldCallId = await this.apiCall({
      method: configJSON.httpPutMethod,
      endPoint: `${configJSON.addSectionEndPoint}/${this.state.enableSectionDetails.id}/field_types/${userId}`,
      contentType: configJSON.searchApiContentType,
      body: JSON.stringify(this.state.field_type)
    });
  }

  editCustomSectionField = (item: { [key: string]: FieldValue } | undefined) => {
    let section = Object.assign(this.state.section, item)
    this.setState({ section, sectionModal: true, enableEditSection: true })
  }

  deleteCustomSectionField = async (userId: FieldValue) => {
    this.deleteCustomSectionFieldCallId = await this.apiCall({
      method: configJSON.httpDeleteMethod,
      contentType: configJSON.searchApiContentType,
      endPoint: `${configJSON.addSectionEndPoint}/${this.state.enableSectionDetails.id}/field_types/${userId}`,
    });
  }

  handleEditButton = () => {
    if (this.state.enableCustomSection) {
      if (!this.state.field_type["name"]) {
        toast.error("Field name is mandatory.", { containerId: "D" });
        return
      }
      if (!this.state.field_type["description"]) {
        toast.error("Field description is mandatory.", { containerId: "D" });
        return
      }
    }
    else {
      if (!this.state.field_educator["name"] && !this.state.field_educator["description"]) {
        toast.error("Please enter field name and description", { containerId: "D" });
        return
      }
      if (!this.state.field_educator["name"]) {
        toast.error("Please enter field name", { containerId: "D" });
        return
      }
      if (!this.state.field_educator["description"]) {
        toast.error("Please enter field description", { containerId: "D" });
        return
      }
    }
    this.checkResponses()
  }

  checkResponses = () => {
    if (this.state.enableEditField && this.state.enableCustomSection) {
      this.editAddedCustomSectionField(Number(this.state.field_type["id"]))
    } else if (this.state.enableEditField) {
      this.editAddedField(this.state.field_educator["id"])
    } else if (this.state.enableCustomSection) {
      this.addCustomSectionField(Number(this.state.enableSectionDetails.id))
    } else {
      this.addField()
    }
  }

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.profile['email'] ? this.state.profile['email'].toString() : "")
  }

  enableEditProfile = () => {
    this.setState({ editProfile: true })
  }

  getAllStudentProfile = async () => {
    this.getAllProfileCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllProfileEndPoint
    });
  }

  enableAllStudentDetails = () => {
    this.setState({ allStudentModal: true })
  }

  enableAllCertificateModal = () => {
    this.setState({ allCertificateModal: true });
  }

  closeCertificateModal = () => {
    this.setState({ allCertificateModal: false })
  }

  editEmployeeProfile = (item: { [key: string]: FieldValue }) => {
    this.setState({ openEmployeeEditModal: true, selectedEmployeeDetail: item })
  }

  updateSingleEmployeeDetail = async (userId: FieldValue) => {
    this.updateSingleEmployeeCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpPutMethod,
      body: JSON.stringify(this.state.selectedEmployeeDetail),
      endPoint: `${configJSON.updateSingleEmployeeEndPoint}/${userId}/update_by_supervisor`
    });
  }

  changeFilterValue = async (event: string) => {
    this.setState({ filterValue: event, loading: true })
    this.getFilterGroupNameCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getFilterGroupEndPoint}?group_name=${event}`
    });
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  disableDates = (event: Moment) => {
    return event && event.valueOf() > Date.now()
  }

  closeConfirmModal = () => {
    this.setState({ confirmModal: false, openEmployeeEditModal: false, allStudentModal: true })
  }

  getGroupName = async () => {
    this.getGroupNameCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getGroupNameEndPoint
    });
  }

  closeAllStudentModal = () => {
    this.setState({ allStudentModal: false })
    this.changeFilterValue("");
  }

  handleTheme = () => {
    this.setState({ enableDarkMode: !this.state.enableDarkMode })
    setStorageData("darkMode",`${!this.state.enableDarkMode}`)
  }

  handleMenu = () => {
    this.setState({ sideMenuOpen: !this.state.sideMenuOpen })
    setStorageData('open',JSON.stringify(!this.state.sideMenuOpen));
  }
  // Customizable Area End
}
