import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Input,
    Button,
    Switch,
    styled,
    Grid,
    TextareaAutosize,
    Snackbar
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import LandingPageController, {
    Props,
} from "./LandingPageController";
import { logoDark, logoLight,toggleDarkLogo,toggleLightLogo,backgroundWelcome,welcomeImage,leftCircleIcon,rightCircleIcon, overcomeImage, innovativeImage, solutionImage, anytimeImage, aiImage, accessIcon, continueImage, imporveIcon, qrImage, petropalIcon, upwardIcon, cocentricImage, aiDark, anytimeDark, continueDark, imporveDark, qrDark, darkBackgroundWelcome, darkBackgroundOvercome, backgroundOvercome, greenTick } from "./assets";

// Customizable Area Start
declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
      custom?: {
        thumbLeft?: string;
        beforeLeft?: string;
        afterLeft?: string;
        challengeImage?: string;
    };
}     
    interface PaletteOptions {
      custom?: {
        thumbLeft?: string;
        beforeLeft?: string;
        afterLeft?: string;
        challengeImage?: string;
    };
    }
  }

const FontStyle = {
    fontFamily: 'Rubik',
    h1: { fontSize: '32px' },
    h2: { fontSize: '30px' },
    h3: { fontSize: '28px' },
    h4: { fontSize: '26px' },
    h5: { fontSize: '24px' },
    h6: { fontSize: '22px' },
    subtitle1: { fontSize: '20px' },
    subtitle2: { fontSize: '18px' },
    body1: { fontSize: '16px' },
    body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
        },
        secondary: {
            main: '#FFFFFF',
        },
        background: {
            default: '#FFFFFF',
            paper: 'rgba(238,238,238,1)'
        },
        text: {
            primary: '#334155',
            secondary: '#475569',
            hint: '#0F172A'
        },
        action: {
            active: '#F1F5F9',
            selected: '#1A469C',
            focus: '#334155',
            disabled: '#475569'
        },
        info: {
            main: "#0F172A",
            light: '#475569',
            dark: '#CBD5E1',
            contrastText:`url(${backgroundWelcome})`
        },
        custom:{
            thumbLeft: '0',
            beforeLeft: '0',
            afterLeft: '35px',
            challengeImage: `url(${backgroundOvercome})`,
        }
    },
    typography: FontStyle
});
export const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#161616',
        },
        secondary: {
            main: '#2F2F2F',
        },
        background: {
            default: '#0D0D0D',
            paper: 'rgba(26,26,26,1)',
        },
        text: {
            primary: '#D5DDF0',
            secondary: '#99A7BB',
            hint: '#F2F2F4'
        },
        action: {
            active: '#70A1FF',
            selected: '#70A1FF',
            focus: '#909EB2',
            disabled: '#96A4B8'
        },
        info: {
            main: "#D5DDF0",
            light: '#99A7BB',
            dark: '#273444',
            contrastText:`url(${darkBackgroundWelcome})`
        },
        custom:{
            thumbLeft: '17px',
            beforeLeft: '-35px',
            afterLeft: '0px',
            challengeImage: `url(${darkBackgroundOvercome})`,
        }
    },
    typography: FontStyle
});
const MainContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: theme.palette.background.default,
    height: 'inherit',
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.primary.main
    }
}))
const NavBar = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    borderBottomLeftRadius:"100px",
    borderBottomRightRadius:"100px",
    height: '129px',
    width:"1440px",
    backgroundColor: theme.palette.background.paper,
}))
const Footer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    borderTopLeftRadius:"100px",
    borderTopRightRadius:"100px",
    height: '264px',
    width:"1440px",
    paddingBottom:"100px",
    backgroundColor: theme.palette.background.paper,
}))
const FooterItems = styled(Box)({
    display: 'flex',
    width:"100%",
    justifyContent: 'space-between',
    marginLeft:"140px",
    marginRight:"150px",
    alignItems:"center",
})
const NavBarItems = styled(Box)({
    display: 'flex',
    width:"100%",
    justifyContent: 'space-between',
    marginLeft:"140px",
    marginRight:"150px",
    alignItems:"center",
})
const ContactUsButton = styled(Button)(({ theme }) => ({
    width: "131px",
    height: "44px",
    gap: "8px",
    borderRadius: "30px",
    cursor:"pointer",
    border: "1px solid #1A469C",
    opacity: "0px",
    "& .MuiButton-label":{
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "left",
        color: "#1A469C",
        width:"100px",
        height:"24px",
    }
}))
const LoginButton = styled(Button)(({ theme }) => ({
    width: "79px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    cursor:"pointer",
    borderRadius: "30px",
    opacity: "0px",
    background: "#12326F !important",
    "& .MuiButton-root:hover":{
        backgroundColor:"#12326F !important",
    },
    "& .MuiButton-label":{
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "left",
        color: "#F8FAFC",
        width:"47px",
        height:"24px",
        textTransform:"none",
    }
}))
const TrainingButton = styled(Button)({
    width: "107px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "30px",
    opacity: "0px",
    marginTop:"25px",
    background: "#12326F !important",
    "& .MuiButton-root:hover":{
        backgroundColor:"#12326F !important",
    },
    "& .MuiButton-label":{
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "left",
        color: "#F8FAFC",
        width:"75px",
        height:"24px",
        textTransform:"uppercase",
    }
})
const ContactButton = styled(Button)({
    width: "220px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "30px",
    opacity: "0px",
    cursor:"pointer",
    background: "#12326F !important",
    "& .MuiButton-root:hover":{
        backgroundColor:"#12326F !important",
    },
    "& .MuiButton-label":{
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#F8FAFC",
        width:"180px",
        height:"24px",
        textTransform:"uppercase",
    }
})
const ContactJoinButton = styled(Button)({
    width: "200px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "30px",
    opacity: "0px",
    background: "#1A469C !important",
    "& .MuiButton-label":{
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#F8FAFC",
        width:"170px",
    }
})
const SubmitButton = styled(Button)({
    width: "534px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "30px",
    opacity: "0px",
    background: "#1A469C !important",
    "& .MuiButton-root:hover":{
        backgroundColor:"#1A469C !important",
    },
    "& .MuiButton-label":{
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#F8FAFC",
        width: "58px",
        height: "24px",
        gap: "0px",
        opacity: "0px"
        }
})


const ThemeSwitch = styled(Switch)(({ theme }) => ({
    width: 77,
    height: 39.2,
    padding: 0,
    borderRadius: 56,
    '& .MuiSwitch-switchBase': {
      margin: 0,
      padding: 0,
      '& .Mui-checked': {
        transform: 'translateX(38px)',
        '& .MuiSwitch-thumb': {
          backgroundColor: theme.palette.action.selected,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          border: `1.4px solid ${theme.palette.secondary.main}`,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: 39.2,
      height: 39.2,
      backgroundColor: theme.palette.action.selected,
      position: 'relative',
      left:theme.palette.custom?.thumbLeft,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: theme.palette.custom?.beforeLeft,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${toggleLightLogo})`,
      },
      '&::after': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: theme.palette.custom?.afterLeft,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${toggleDarkLogo})`,  
    },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: `${theme.palette.background.default} !important`,
        borderRadius: 56 / 2,
        border: `1.4px solid ${theme.palette.secondary.main}`,
      },
  }));
const WelcomeGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    flexDirection: 'row',
    background: theme.palette.background.default,
    backgroundImage: theme.palette.info.contrastText,
    width:"1440px",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 'inherit',
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.background.paper,
    },
    '@media (max-width: 1400px)': {
        width: '100%',
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'center',
        padding: '20px', 
        height: 'auto', 
      },
}))
const ImageGridContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: theme.palette.background.default,
    backgroundRepeat: 'no-repeat',
    borderRadius:"30px",
    backgroundPosition: 'center',
    height: 'inherit',
    position:"relative",
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.primary.main
    }
}))
const WelcomeTypography=styled(Typography)(({theme})=>({
    width: "355px",
    height: "32px",
    fontFamily: "Rubik",
    fontSize: "26px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: theme.palette.text.hint,
}))
const ContactTypography=styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
  fontSize: "26px",
  fontWeight: 500,
  lineHeight: "32px",
  letterSpacing: "-0.005em",
  textAlign: "left",
  width: "190px",
  height: "32px",
  gap: "0px",
  opacity: "0px",
  color: theme.palette.text.hint,
}))
const FooterTypography = styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: theme.palette.text.hint,
    width: "840px", height: "72px", gap: "0px", opacity: "0px"
}))
const CopyrightTypography = styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginTop:"20px",
}))
const SubHeadingTypography=styled(Typography)(({theme})=>({
    width: "810px",
    height: "106px",
    fontFamily: "Rubik",
    fontSize: "52px",
    fontWeight: 700,
    lineHeight: "53px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: theme.palette.text.hint,
}))
const SubHeading2Typography=styled(Typography)(({theme})=>({
    width: "724px",
    height: "96px",
    fontFamily: "Rubik",
    fontSize: "38px",
    fontWeight: 700,
    lineHeight: "48px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color:theme.palette.text.hint,
}))
const SubHeading3Typography=styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "38px",
    fontWeight: 700,
    lineHeight: "42px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    width: "709px",
    height: "84px",
    color:theme.palette.text.hint,
}))
const SubHeading4Typography=styled(Typography)(({theme})=>({
    width: "795px",
    height: "84px",
    fontFamily: "Rubik",
    fontSize: "38px",
    fontWeight: 700,
    lineHeight: "42px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color:theme.palette.text.hint,
}))
const SubHeading5Typography=styled(Typography)(({theme})=>({
    width: "709px",
    height: "84px",
    fontFamily: "Rubik",
    fontSize: "38px",
    fontWeight: 700,
    lineHeight: "42px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color:theme.palette.text.hint,
}))
const ContactSubHeadTypography=styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "42px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    width: "510px",
    height: "84px",
    gap: "0px",
    opacity: "0px",
    color: theme.palette.text.hint,
}))
const DetailsTypography=styled(Typography)(({theme})=>({
    width: "713px",
    height: "140px",
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 400,
    lineHeight: "35px",
    textAlign: "left",
    color: theme.palette.text.primary,
}))
const Details2Typography=styled(Typography)(({theme})=>({
    width: "714px",
    height: "108px",
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "26px",
    textAlign: "left",
    color: theme.palette.text.primary,
}))
const Details3Typography=styled(Typography)(({theme})=>({
    width: "708px",
  height: "248px",
  fontFamily: "Rubik",
  fontSize: "24px",
  fontWeight: 400,
  lineHeight: "30px",
  textAlign: "left",
  color: theme.palette.text.primary,
}))
const Details4Typography=styled(Typography)(({theme})=>({
    width: "714px",
    height: "276px",
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "26px",
    textAlign: "left",
    color: theme.palette.text.primary,
}))
const Details5Typography=styled(Typography)(({theme})=>({
    width: "708px",
    height: "248px",
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "30px",
    textAlign: "left",
    color: theme.palette.text.primary,
}))

const ContactDetailsTypography=styled(Typography)(({theme})=>({
    width: "559px",
    height: "95px",
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "26px",
    textAlign: "left",
    color: theme.palette.text.primary,
}))

const DetailsBox=styled(Box)(({theme})=>({
    width: "957px",
    height: "299px",
    borderRadius: "30px",
    borderTopRightRadius:"100px",
    background: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    alignItems: "last baseline",
    padding: "30px",
    '@media (max-width: 1400px)': {
    alignItems:"baseline !important"
    }
}))
const ContactDetailsBox=styled(Box)(({theme})=>({
    width: "670px",
    background: theme.palette.background.paper,
    height: "324px",
    borderRadius: "100px 30px 30px",
    angle: "-180 deg",
    display: "flex",
    flexDirection: "column",
    alignItems: "last baseline",
    padding: "30px"
}))
const SecondDetailsBox=styled(Typography)(({theme})=>({
    width: "962px",
    height: "216px",
    left: "1400px",
    gap: "0px",
    opacity: "0px",
    borderRadius: "30px",
    borderBottomLeftRadius:"100px",
    background: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    padding: "30px",
    '@media (max-width: 1400px)': {
        alignItems:"flex-end !important"
    }
}))
const SliderIconsBox=styled(Box)(({theme})=>({
    gap: "10px",
    top: "92%",
    left: "66%",
    width: "78px",
    height: "32px",
    display: "flex",
    zIndex: 3,
    position: "absolute"
}))
const SliderSecondIconsBox=styled(Box)(({theme})=>({
    gap: "10px",
    top: "92%",
    left: "25%",
    width: "78px",
    height: "32px",
    display: "flex",
    zIndex: 3,
    position: "absolute"
}))
const DetailGrid = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "baseline",
    gap: "40px",
}))
const ContactFormGrid=styled(Grid)(({theme})=>({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center"
}))
const SecondDetailGrid = styled(Grid)(({ theme }) => ({
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap:"40px",
}))
const ContactDetailGrid=styled(Grid)(({theme})=>({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "baseline",
    '@media (max-width: 1400px)': {
        alignItems: 'flex-end !important', 
       },
}))
const ChallengeGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    flexDirection: 'row',
    backgroundImage: theme.palette.custom?.challengeImage,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width:"1440px",
    height: 'inherit',
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.primary.main
    },
    '@media (max-width: 1400px)': {
        width: '100%',
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'center',
        padding: '20px', 
        height: 'auto', 
      },
}))
const ColorTypography = styled('span')(({theme})=>({
    color: theme.palette.action.selected,
}))

const ChoosePetroPalGrid = styled(Box)(({theme})=>({
    width: "1240px",
    height: "325px",
    gap: "20px",
    borderRadius: "100px 30px 100px 30px",
    opacity: "0px",
    background:theme.palette.background.paper,
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
}))
const JoinPetroPalGrid = styled(Box)(({theme})=>({
    width: "1240px",
    height: "325px",
    gap: "20px",
    borderRadius: "100px 30px 100px 30px",
    opacity: "0px",
    background:theme.palette.background.paper,
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
}))

const FormTextArea=styled(TextareaAutosize)(({theme})=>({
    width: "486px",
    height: "142.41px",
    padding: "18px 24px 18px 24px",
    gap: "4.63px",
    borderRadius: "30px",
    opacity: "0px",
    border: `1.16px solid ${theme.palette.info.dark}`,
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "left",
    color:theme.palette.action.disabled,
    backgroundColor:theme.palette.background.default,
    resize:"none",
}))

const HeadingTypograhy = styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "38px",
    fontWeight: 700,
    lineHeight: "42px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    color: theme.palette.text.hint,
}))

const JoinUsHeadingTypograhy = styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "34px",
    fontWeight: 700,
    lineHeight: "42px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    color: theme.palette.text.hint,
}))

const GridTypography = styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "30px",
    textAlign: "center",
    color: theme.palette.text.primary,
}))

const JoinUsTypography = styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "28px",
    textAlign: "center",
    color: theme.palette.text.primary,
}))

const FeatureGridContainer=styled(Grid)(({
    width: "1440px",
    height: "842px",
}))
const TopFeatureGrid=styled(Grid)(({
    gap:"12.5%",
    '@media (max-width: 1400px)': {
        gap:"9.5%",
        justifyContent:"center",
    }
}))

const ContactUsGrid=styled(Grid)(({
    width: "1440px",
    height: "605px",
    backgroundImage:`url(${backgroundWelcome})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
}))

const FooterGridContainer=styled(Grid)(({
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
}))
const AIGrid=styled(Grid)(({theme})=>({
    width: "569px",
    height: "496px",
    padding:"60px !important",
    borderRadius: "30px 100px 100px 30px",
    background:theme.palette.background.paper
}))
const RTAGrid=styled(Grid)(({theme})=>({
    width: "396px",
    height: "496px",
    padding:"60px !important",
    borderRadius: "100px",
    opacity: "0px",
    background:theme.palette.background.paper
}))
const ContGrid=styled(Grid)(({theme})=>({
    width: "534px",
    height: "496px",
    borderRadius: "100px 30px 30px 100px",
    padding:"60px !important",
    background:theme.palette.background.paper
}))
const ImpGrid=styled(Grid)(({theme})=>({
    width: "746px",
    height: "310px",
    padding:"45px !important",
    borderRadius: "30px 100px 100px 30px",
    background:theme.palette.background.paper
}))
const QRGrid=styled(Grid)(({theme})=>({
    width: "745px",
    height: "310px",
    padding:"45px !important",
    borderRadius: "100px 30px 30px 100px",
    background:theme.palette.background.paper
}))
const FooterGridItem=styled(Grid)(({
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
}))
const InfoBox=styled(Box)(({
    display:"flex",
    justifyContent:"flex-start",
    alignItems:"center",
    marginBottom:"30px",
    marginLeft:"15%",
}))
const InfoTopBox=styled(Box)(({
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"flex-start",
}))
const ContactFormBox=styled(Box)(({theme})=>({
    width: "534px",
    height: "529.97px",
    padding: "55.57px 24px 37.05px 24px",
    borderRadius: "30px",
    opacity: "0px",
    background: theme.palette.background.paper,
    margin:"12px",
    display:"flex",
    justifyContent:"center",
    alignItems:"flex-baseline",
    flexDirection:"column",
}))
const SubmitBox=styled(Box)(({theme})=>({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    gap:"50px",
}))
const InfoHeadTypography=styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: theme.palette.text.hint,
}))
const InfoDetailsTypography=styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "left",
    color: theme.palette.text.primary,
}))

const FormTypography=styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "18.52px",
    fontWeight: 400,
    lineHeight: "27.79px",
    textAlign: "left",
    color: theme.palette.action.focus,
}))

const MessageTypo=styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: theme.palette.action.focus,
}))
const ErrorTypo=styled(Typography)(({theme})=>({
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
    margintop: "5px",
    color: "red",
}))
const CustomSnackbar=styled(Snackbar)(({theme})=>({
    width: "534px",
    height: "42px",
    padding: "8px 16px 8px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.background.default}`,
    opacity: "0px",
    boxShadow: "0px 6px 15px -3px #00000026",
    position:"relative"
}))

const InputField = styled('input')(({ theme }) => ({
    width: "486px",
    height: "49.16px",
    gap: "18.52px",
    borderRadius: "30px",
    opacity: "0px",
    border: `1.16px solid ${theme.palette.info.dark}`,
    background: "#FFFFFF",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "left",
    padding:"24px",
    color:theme.palette.action.disabled,
    backgroundColor:theme.palette.background.default,
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'transparent'
    },
    '& .MuiInput-underline:after': {
        borderBottom: 'transparent'
    }
  }))
  
// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
        // Required for all blocks
        <ThemeProvider theme={this.state.darkMode ? darkTheme:lightTheme}>
            <MainContainer data-test-id="maincontainethemetest">
                <NavBar data-test-id="navbar">
                    <NavBarItems>
                        <div className="logo">
                            <img src={this.state.darkMode ? logoLight:logoDark} data-test-id="darkThemeTest" alt="logo_petropal"/>
                        </div>
                        <div style={{width:"333px",display:"flex",justifyContent:"center",gap:"12px"}}>
                            <ThemeSwitch checked={this.state.darkMode} data-test-id="darkmodeswitch" onChange={this.handleThemeChange}></ThemeSwitch>
                            <ContactUsButton data-test-id="contactFormGoToTest" onClick={this.goToContactForm}>Contact Us</ContactUsButton>
                            <LoginButton onClick={this.goToLoginPage}>Log in</LoginButton>
                        </div>
                    </NavBarItems>
                </NavBar>
                <WelcomeGrid container spacing={2} style={{margin:"45px"}} ref={this.topRef}>
                    <DetailGrid item sm={12} md={8}>
                        <Box>
                        <WelcomeTypography>WELCOME TO PETROPAL</WelcomeTypography>
                        <SubHeadingTypography>Augmented Industry Intelligence for <ColorTypography>Oil and Gas</ColorTypography></SubHeadingTypography>
                        </Box>
                        <DetailsBox>
                            <DetailsTypography>
                                "Transform your operations with PetroPal's AI solutions, blending innovation with industry expertise. Empower your team and elevate the oil & gas sector. Explore PetroPal's vision for a safer, efficient future."
                            </DetailsTypography>
                            <TrainingButton>TRAINING</TrainingButton>
                        </DetailsBox>
                    </DetailGrid>
                    <Grid item sm={12} md={4}>
                        <ImageGridContainer>
                            <img src={welcomeImage}/>
                            <SliderIconsBox>
                                <img src={leftCircleIcon}/>
                                <img src={rightCircleIcon}/>
                            </SliderIconsBox>
                        </ImageGridContainer>
                    </Grid>
                </WelcomeGrid>
                <ChallengeGrid container spacing={2} style={{margin:"45px"}}>
                    <Grid item sm={12} md={4}>
                        <ImageGridContainer>
                            <img src={overcomeImage}/>
                        </ImageGridContainer>
                    </Grid>
                    <SecondDetailGrid item sm={12} md={8}>
                        <SecondDetailsBox>
                            <WelcomeTypography style={{width:"152px",height:"32px",fontSize:"24px"}}>Overcome</WelcomeTypography>
                            <SubHeading2Typography>Key Industry <ColorTypography>Challenges</ColorTypography> with PetroPal</SubHeading2Typography>
                        </SecondDetailsBox>
                        <Box>
                            <Details2Typography>
                                "The oil & gas industry faces challenges in engaging employees and ensuring adherence to best practices. Technological advancements have outpaced training methods, leading to gaps in standardization and competency verification.
                            </Details2Typography>
                            <Details2Typography>
                                This can result in safety, compliance, and productivity issues, as well as increased costs. PetroPal offers a solution leveraging cutting-edge technology to address these concerns comprehensively."
                            </Details2Typography>
                            <ContactButton style={{marginTop:"25px",cursor:"pointer"}} onClick={this.goToContactForm}>CONTACT <span style={{textTransform:"capitalize",marginLeft:"5px"}}>Petro Pal</span></ContactButton>  
                        </Box>
                        </SecondDetailGrid>
                </ChallengeGrid>
                <WelcomeGrid container spacing={2} style={{margin:"45px"}}>
                    <DetailGrid item sm={12} md={8}>
                        <WelcomeTypography style={{width:"514px",height:"32px",fontSize:"26px"}}>PETROPAL’S INNOVATIVE APPROACH</WelcomeTypography>
                        <SubHeading3Typography>Empower Your Decisions with <ColorTypography>Expert Knowledge</ColorTypography></SubHeading3Typography>
                        <DetailsBox>
                            <Details3Typography>
                                "At PetroPal, we tackle industry challenges with our revolutionary solution. Our dynamic, mobile-first strategies, paired with AI advancements, offer on-demand training to bridge gaps and ensure uniform competency.
                            </Details3Typography>
                            <Details3Typography>
                                Studies show that intuitive, on-the-go learning reduces human error, addressing the industry's safety needs. PetroPal's e-learning platform and AI-driven bots uphold best practices, fostering informed and safe employees."
                            </Details3Typography>
                        </DetailsBox>
                    </DetailGrid>
                    <Grid item sm={12} md={4}>
                        <ImageGridContainer>
                            <img src={innovativeImage}/>
                        </ImageGridContainer>
                    </Grid>
                </WelcomeGrid>
                <ChallengeGrid container spacing={2} style={{margin:"45px"}}>
                    <Grid item sm={12} md={4}>
                        <ImageGridContainer>
                            <img src={solutionImage}/>
                            <SliderSecondIconsBox>
                                <img src={leftCircleIcon}/>
                                <img src={rightCircleIcon}/>
                            </SliderSecondIconsBox>
                        </ImageGridContainer>
                    </Grid>
                    <SecondDetailGrid item sm={12} md={8}>
                        <SecondDetailsBox>
                            <WelcomeTypography style={{width:"552px",height:"32px",fontSize:"24px"}}>INTRODUCING THE PETROPAL SOLUTION</WelcomeTypography>
                            <SubHeading4Typography><ColorTypography>AI Chatbots</ColorTypography> and Dynamic Equipment Tagging</SubHeading4Typography>
                        </SecondDetailsBox>
                        <Box style={{display:"flex",flexDirection:"column",gap:"100%"}}>
                        <Details4Typography>
                            PetroPal AI bots empower oil & gas training. Integrate best practices & regulations for real-time knowledge via chat or QR scans. Bridge knowledge gaps with interactive formats (videos, 3D models). Empower informed, safe employees.
                            This version focuses on the core functionalities:
                            <ul>
                                <li>AI chatbots for training</li>
                                <li>Real-time knowledge access</li>
                                <li>Bridging knowledge gaps</li>
                                <li>Interactive learning formats</li>
                                <li>Safety focus</li>
                            </ul>
                        </Details4Typography>
                        </Box>
                        </SecondDetailGrid>
                </ChallengeGrid>
                <WelcomeGrid container spacing={2} style={{margin:"45px"}}>
                    <DetailGrid item sm={12} md={8}>
                        <Box style={{display:"flex",justifyContent:"center",alignContent:"center",flexDirection:"column"}}>
                            <WelcomeTypography>Overcome</WelcomeTypography>
                            <SubHeading5Typography>Access Information <ColorTypography> Anytime, Anywhere</ColorTypography></SubHeading5Typography>
                        </Box>
                        <DetailsBox>
                            <Details5Typography>
                            As your constant companion for oil and gas insights, PetroPal ensures you stay ahead of training, industry trends, and best practices.
                            Our user-friendly and reliable application offers unmatched convenience and ease of use, ensuring that your operations continue regardless of your location. Available for iOS and Android, the PetroPal app puts the latest industry practices at your fingertips.
                            </Details5Typography>
                        </DetailsBox>
                    </DetailGrid>
                    <Grid item sm={12} md={4}>
                        <ImageGridContainer>
                            <img src={anytimeImage}/>
                        </ImageGridContainer>
                    </Grid>
                </WelcomeGrid>
                <ChoosePetroPalGrid style={{margin:"45px"}}>
                    <HeadingTypograhy>
                        Why Choose <ColorTypography>PetroPal</ColorTypography> ?
                    </HeadingTypograhy>
                    <GridTypography>
                        With so many solutions for the oil and gas industry, PetroPal stands out for its AI-driven training and optimization integration that delivers tangible benefits for employees and employers. When you choose PetroPal, you receive several benefits 
                    </GridTypography>
                    <ContactButton onClick={this.goToContactForm}>CONTACT <span style={{textTransform:"capitalize",marginLeft:"5px"}}>Petro Pal</span></ContactButton>    
                </ChoosePetroPalGrid>
                <FeatureGridContainer container spacing={4} style={{margin:"45px"}}>
                    <TopFeatureGrid container>
                    <AIGrid item xs={3} md={3}>
                        <InfoTopBox style={{height:"100%",gap:"5%"}}>
                            <img src={(this.state.darkMode) ? aiDark:aiImage} style={{width:"80px",height:"80px"}}/>
                            <InfoHeadTypography><ColorTypography>Experience</ColorTypography> the future</InfoHeadTypography>
                            <InfoDetailsTypography style={{width: "288px",height: "220px"}}>Experience the future of the oil and gas industry, as AI has been shown to have overwhelmingly positive benefits for employees and employers. With businesses turning to AI to streamline operations, accelerate your organization’s success by starting with augmented solutions curated to your exact needs.</InfoDetailsTypography>
                        </InfoTopBox>
                    </AIGrid>
                    <RTAGrid item xs={3} md={3}>
                        <InfoTopBox style={{height:"90%",gap:"5%"}}>
                            <img src={(this.state.darkMode) ? anytimeDark:accessIcon} style={{width:"80px",height:"80px"}}/>
                            <InfoHeadTypography><ColorTypography>Real-Time</ColorTypography> Access</InfoHeadTypography>
                            <InfoDetailsTypography style={{width: "290px",height: "176px"}}>Employees gain real-time access to information, enhancing their job-related decision-making and significantly improving company safety measures. Empower your employees to make decisions that are effective, safe, and on track with your firm’s goals.</InfoDetailsTypography>
                        </InfoTopBox>
                    </RTAGrid>
                    <ContGrid item xs={3} md={3}>
                        <InfoTopBox style={{height:"100%",gap:"5%"}}>
                            <img src={(this.state.darkMode) ? continueDark : continueImage} style={{width:"80px",height:"80px"}}/>
                            <InfoHeadTypography><ColorTypography>Continuous</ColorTypography> Updates</InfoHeadTypography>
                            <InfoDetailsTypography style={{width: "290px",height: "198px"}}>Continuous updates ensure your information is the most recent available, keeping your entire company apprised of industry changes to remain ahead of competitors. When updates arrive, upload them to the chatbot to allow PetroPal to continue to support you.</InfoDetailsTypography>
                        </InfoTopBox>
                    </ContGrid>
                    </TopFeatureGrid>
                    <Grid container style={{gap:"16.5%"}}>
                    <ImpGrid item xs={4} md={5}>
                        <InfoBox>
                            <img src={(this.state.darkMode) ? imporveDark : imporveIcon} style={{width:"64.2px",height:"64.2px",marginRight:"53.51px"}}/>
                            <InfoHeadTypography><ColorTypography>Improve</ColorTypography> Performance</InfoHeadTypography>
                        </InfoBox>
                        <Box style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <InfoDetailsTypography style={{width:"505px",height:"110px"}}>Employers improve operational performance, elevating their safety expectations and experiencing a substantial reduction in downtime. Ensure that your entire company understands what is expected while reaching to meet these standards.</InfoDetailsTypography>
                        </Box>
                        
                    </ImpGrid>
                    <QRGrid item xs={4} md={5}>
                        <InfoBox>
                            <img src={(this.state.darkMode) ? qrDark:qrImage} style={{width:"65px",height:"65px",marginRight:"41px"}}/>
                            <InfoHeadTypography><ColorTypography>QR</ColorTypography> code access</InfoHeadTypography>
                        </InfoBox>
                        <Box style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <InfoDetailsTypography style={{width:"550px",height:"110px"}}>Enjoy QR code access at the touch of a button. Our technology allows you to scan a QR code on your company’s equipment to access the information required for safe use. With our QR codes, all your questions are answered to ensure safe, secure operations.</InfoDetailsTypography>
                        </Box>
                    </QRGrid>
                    </Grid>
                </FeatureGridContainer>
                <JoinPetroPalGrid style={{margin:"45px"}}>
                    <JoinUsHeadingTypograhy>
                    Join the FUTURE of <ColorTypography>the Oil and Gas Company</ColorTypography>
                    </JoinUsHeadingTypograhy>
                    <JoinUsTypography>
                        As a beacon of innovation for oil and gas optimization and training, PetroPal invites you to join us in shaping a future where safer and remarkably more efficient operations are the standard for every company. When you choose PetroPal, your potential is limitless, as is your ability to access critical, up-to-date industry insights.
                    </JoinUsTypography>
                    <ContactJoinButton onClick={this.goToContactForm}>CONTACT <span style={{textTransform:"capitalize",marginLeft:"5px"}}>Petro Pal</span></ContactJoinButton>    
                </JoinPetroPalGrid>
                <ContactUsGrid container style={{margin:"45px"}}>
                    <ContactDetailGrid item sm={12} md={6}>
                        <ContactTypography>CONTACT US</ContactTypography>
                        <ContactSubHeadTypography>Contact PetroPal to <ColorTypography>Improve Your Company’s</ColorTypography> Operations</ContactSubHeadTypography>
                        <ContactDetailsBox>
                            <ContactDetailsTypography>
                                Ready to transform your oil and gas operations with PetroPal? We're here to assist you every step of the way.
                            </ContactDetailsTypography>
                            <ContactDetailsTypography>
                                To inquire or learn more about how PetroPal can revolutionize your operations, please fill out the contact form below, and we will contact you as soon as possible.
                            </ContactDetailsTypography>
                        </ContactDetailsBox>
                    </ContactDetailGrid>
                    <ContactFormGrid item md={6} sm={6} ref={this.contactRef}>
                        <ContactFormBox>
                            <FormTypography>Name</FormTypography>
                            <InputField placeholder="Enter your name" data-test-id="txtInputName" value={this.state.name} onChange={this.handleNameChange} ></InputField>
                            {this.state.error.nameError && <ErrorTypo data-test-id="nameError">{this.state.error.nameError}</ErrorTypo>}
                            <FormTypography style={{marginTop:"20px"}}>Email</FormTypography>
                            <InputField placeholder="Enter your email" data-test-id="txtInputEmail" value={this.state.email} onChange={this.handleEmailChange}></InputField>
                            {this.state.error.emailError && <ErrorTypo data-test-id="emailError">{this.state.error.emailError}</ErrorTypo>}
                            <FormTypography style={{marginTop:"20px"}}>Subject</FormTypography>
                            <InputField placeholder="Enter your subject" data-test-id="txtInputSubject" value={this.state.subject} onChange={this.handleSubjectChange}></InputField>
                            {this.state.error.subjectError && <ErrorTypo data-test-id="SubjectError">{this.state.error.subjectError}</ErrorTypo>}
                            <FormTypography style={{marginTop:"20px"}}>Your message</FormTypography>
                            <FormTextArea minRows={7} placeholder="Enter your message" data-test-id="txtInputMessage" value={this.state.message} onChange={this.handleMessageChange} ></FormTextArea>
                            {this.state.error.messageError && <ErrorTypo data-test-id="messageError">{this.state.error.messageError}</ErrorTypo>}
                        </ContactFormBox>
                        <SubmitBox>
                            <SubmitButton data-test-id="contactSubmitTest" onClick={this.submitContactForm}>Submit</SubmitButton>
                            <CustomSnackbar open={this.state.openSnackbar}
                                autoHideDuration={6000}
                                onClose={this.handleCloseSnackbar}>
                                    <>
                                        <img src={greenTick} style={{width:"26px",height:"26px"}}/>
                                        <MessageTypo data-test-id="messageSentTest">Thank you for your message. It has been sent.</MessageTypo>
                                    </>
                            </CustomSnackbar>    
                        </SubmitBox>
                    </ContactFormGrid>   
                </ContactUsGrid>
                <Footer style={{marginTop:"147px"}}> 
                    <FooterItems>
                        <FooterGridContainer style={{backgroundImage:`url(${cocentricImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',}}>
                        <img src={upwardIcon} style={{cursor:"pointer"}} data-test-id="topGoToTest" onClick={this.goToTopSection}/>
                            <FooterGridItem>
                                <Grid md={4} lg={2}>
                                    <img src={petropalIcon} 
                                    style={{width: "161.48px",height: "156.12px",padding: "18.97px 31.62px 18.97px 31.62px",gap: "18.97px",borderRadius: "25.29px",opacity: "0px"}}/>
                                </Grid>
                                <Grid md={8} lg={10}>
                                    <FooterTypography>
                                    As industry standards continue to be redefined, PetroPal commits itself to being your partner in progress. Together, we can guide you toward success in the oil and gas sector’s ever-evolving landscape. 
                                    </FooterTypography>
                                    <CopyrightTypography>
                                        © Copyright Petropal.ai All Rights Reserved
                                    </CopyrightTypography>
                                </Grid>
                            </FooterGridItem>
                        </FooterGridContainer>

                    </FooterItems>
                </Footer>
            </MainContainer>
        </ThemeProvider>
       // Customizable Area End
    );
  }
}
