import React from "react";

// Customizable Area Start
import { Modal } from "antd";
import { styled, ThemeProvider } from "@material-ui/core/styles";
import { lightTheme, NextFormButton, TermsChecked, darkTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { download } from './assets'
import { Button, Typography } from "@material-ui/core";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.state.lightMode ? lightTheme : darkTheme}>
        <FieldModal
          data-test-id="closeTermsModal"
          open={this.props.openTermsModal}
          onCancel={this.props.closeTermsModal}
          title={
            <h1 className="heading">
              {this.state.termsConditions.title}
            </h1>
          }
          centered
          closable={true}
          footer={null}
          destroyOnClose
          style={{ height: '90vh' }}
        >
          <div>
            <div className="scrollModal">
              <p className="content">Last Revised: {this.state.termsUpdatedDate}</p>
              <span className="content"
                dangerouslySetInnerHTML={{ __html: `${this.state.termsConditions?.description}` }}
              ></span>
            </div>
            <BlurDiv />
          </div>
          <NextFormButton
            data-test-id="addFieldButton"
            style={{ width: '100%' }}
            onClick={this.props.closeTermsModal}
          >
            Continue
          </NextFormButton>
        </FieldModal>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const FieldModal = styled(Modal)(({ theme }) => ({
  height: '380px',
  width: '766px !important',
  gap: '24px',
  padding: '48px',
  border: `1px solid ${theme.palette.primary.main}`,
  background: theme.palette.primary.main,
  borderRadius: '30px',
  "& .ant-modal-close-x": {
    textAlign: 'right'
  },
  "& .ant-modal-body": {
    padding: 0,
    "& .scrollModal": {
      overflowY: 'scroll',
      height: "50vh",
      "& .content": {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px'
      }
    },
    "& .scrollModal::-webkit-scrollbar-track": {
      background: 'transparent',
      borderRadius: '30px 30px 90px 30px',
      border: '1px solid #CBD5E1'
    },
    "& .scrollModal::-webkit-scrollbar": {
      width: '13px',
      borderRadius: '30px 30px 90px 30px'
    },
    "& .scrollModal::-webkit-scrollbar-thumb": {
      borderRadius: '30px 30px 90px 30px',
      border: '1px solid #94A3B8',
      backgroundColor: '#EDF1F6',
      width: '13px',
      height: '43px'
    },
  },
  "& .ant-modal-header": {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    paddingLeft: 0,
    "& .heading": {
      fontSize: '30px',
      fontWeight: 500,
      fontFamily: 'Rubik',
      lineHeight: '0px',
      color: theme.palette.secondary.main
    }
  },
  "& .ant-modal-content": {
    boxShadow: 'none',
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main
  },
  "& .MuiFormControl-root": {
    width: '100%',
    margin: '5px',
  },
  "& .anticon": {
    top: '16px',
    position: 'relative',
    fill: theme.palette.info.contrastText
  }
}))

const BlurDiv = styled('div')(({ theme }) => ({
  height: '84px',
  width: '100%',
  opacity: 0.5,
  position: 'absolute',
  bottom: '65px',
  borderRadius: '0px 0px 30px 30px',
  background: theme.palette.action.focus
}))

// Customizable Area End
