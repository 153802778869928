/* istanbul ignore file */
import React from 'react'
import { getStorageData, setStorageData } from '../../../framework/src/Utilities';

export interface Emails {
    id: string;
    type: string;
    attributes: {
        email: string;
        user_type: string;
        activated: boolean;
        device_id: string;
    };
}

export interface NotificationData {
    message: string;
    description: string;
    id: string;
}
export interface NotificationProps {
    data: Notifications[];
    errors: {
        push_notification: string;
    };
    error: object
}
export interface GetEmailProps {
    data: Emails[];
    errors: {
        [key: string]: string;
    }[];
    error: object
}
export interface UserDataProps {
    device_id?: string,
    email?: string,
    role?: string,
    id?: number
}
export interface Notifications {
    id: string;
    type: string;
    attributes: {
        push_notificable_id: number;
        push_notificable_type: string;
        header: string;
        remarks: string;
        is_read: boolean;
        created_at: string;
        updated_at: string;
        account: {
            data: {
                id: string;
                type: string;
                attributes: {
                    email: string;
                    user_type: string;
                    activated: boolean;
                    device_id: string;
                }
            }
        }
    }
}

export interface SignUpResponse {
    data: {
        id: string;
        type: string;
        attributes: {
            email: string;
            user_type: string;
            activated: boolean;
            device_id: string;
        };
    };
    meta: {
        token: string;
    };
    errors: {
        account: string;
    }[];
    error: object
}

export interface LoginState {
    userToken: string | null;
}
export interface LogInResponse {
    // Customizable Area Start
    meta: {
        token: string;
        id: number;
        user_type: string;
        device_id: string;
    };
    errors: [
        {
            failed_login: string;
        }
    ];
    // Customizable Area End
}
interface RetrieveTokenAction {
    type: 'RETRIEVE_TOKEN';
    token: string;
}

export interface FoundUserProps {
    token: string;
}

interface LogoutAction {
    type: 'LOGOUT';
}

export type LoginAction = RetrieveTokenAction | LogoutAction;

export interface AuthContextType {
    loginState: LoginState;
    dispatch: React.Dispatch<LoginAction>;
    authContext: {
        signIn: (foundUser: FoundUserProps, from: string) => Promise<void>;
        signOut: () => Promise<void>;
    };
}

export const AuthContext = React.createContext<AuthContextType | undefined>(undefined);
export const getToken = async (dispatch: React.Dispatch<LoginAction>) => {
    let userToken;
    userToken = null
    try {
        userToken = await getStorageData('Token');
        let token = JSON.parse(userToken);
        if (token != "" && token != undefined && token != null) {
            dispatch({ type: 'RETRIEVE_TOKEN', token: token });
        }
    } catch (error) {
        // Put a log to catch an error
    }
}

export const notification = [
    {
        "id": "12",
        "type": "push_notification",
        "attributes": {
            "push_notificable_id": 32,
            "push_notificable_type": "AccountBlock::Account",
            "header": "Notification Header",
            "remarks": "Notification Description",
            "is_read": false,
            "created_at": "2023-02-21T07:11:28.180Z",
            "updated_at": "2023-02-21T07:11:28.180Z",
            "account": {
                "data": {
                    "id": "33",
                    "type": "email_account",
                    "attributes": {
                        "email": "ani-f2@gmail.com",
                        "user_type": "normal_user",
                        "activated": true,
                        "device_id": ""
                    }
                }
            },
            "looking_for": [],
            "profile_image": {}
        }
    },
]

export const notificationEmptyData = [
    {
        "id": "12",
        "type": "push_notification",
        "attributes": {
            "push_notificable_id": 32,
            "push_notificable_type": "AccountBlock::Account",
            "header": "",
            "remarks": "",
            "is_read": false,
            "created_at": "2023-02-21T07:11:28.180Z",
            "updated_at": "2023-02-21T07:11:28.180Z",
            "account": {
                "data": {
                    "id": "33",
                    "type": "email_account",
                    "attributes": {
                        "email": "ani-f2@gmail.com",
                        "user_type": "normal_user",
                        "activated": true,
                        "device_id": ""
                    }
                }
            },
            "looking_for": [],
            "profile_image": {}
        }
    },
]

export const emailList = [
    {
        "id": "28",
        "type": "email_account",
        "attributes": {
            "email": "navin-b1@yopmail.com",
            "user_type": "business_user",
            "activated": true,
            "device_id": "askjsbjkfkjbtest"
        }
    }
]
// Customizable Area Start 
export const notificationResponse: NotificationProps = {
    "data": [
        {
            "id": "12",
            "type": "push_notification",
            "attributes": {
                "push_notificable_id": 32,
                "push_notificable_type": "AccountBlock::Account",
                "header": "from business notification",
                "remarks": "description for notification",
                "is_read": false,
                "created_at": "2023-02-21T07:11:28.180Z",
                "updated_at": "2023-02-21T07:11:28.180Z",
                "account": {
                    "data": {
                        "id": "33",
                        "type": "email_account",
                        "attributes": {
                            "email": "ani-f2@gmail.com",
                            "user_type": "normal_user",
                            "activated": true,
                            "device_id": "test"
                        }
                    }
                }
            }
        },
    ],
    "error": {
        "key": "value"
    },
    "errors": {
        "push_notification": "test"
    }
}
export const error = {
    "key": "value"
}

export interface INotifications {
    "id": string;
    "type": "dailyschedulenotification";
    "attributes": {
        "account_id": number;
        "student_course_assignment_id": number;
        "remarks": string;
        "assigned_by": {
            "id": number;
            "first_name": string;
            "last_name": string;
            "preferred_name": string;
            "email": string;
            "full_phone_number": string;
            "date_of_birth": string;
            "role_id": string;
            "location": string;
            "company_reference": string;
            "employee_number": string;
            "completed_course": string;
            "account_id": number;
            "created_at": string;
            "updated_at": string;
            "phone_number_verified": boolean;
            "group_id": number;
            "company_id": number;
            "company_name": string;
            "selected_theme": string;
            "font_size": string;
            "image": string;
        },
        "assigned_to": {
            "id": number;
            "first_name": string;
            "last_name": string;
            "preferred_name": string;
            "email": string;
            "full_phone_number": string;
            "date_of_birth": string;
            "role_id": string;
            "location": string;
            "company_reference": string;
            "employee_number": string;
            "completed_course": string;
            "account_id": number;
            "created_at": string;
            "updated_at": string;
            "phone_number_verified": boolean;
            "group_id": number;
            "company_id": number;
            "company_name": string;
            "selected_theme": string;
            "font_size": string
        },
        "course": {
            "data": {
                "id": string;
                "type": "course";
                "attributes": {
                    "course_name": string;
                    "image": string;
                }
            }
        }
    }
}

export interface INotificationProps {
    data: INotifications[];
    errors: string;
    error: object
}
// Customizable Area End