Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpDeleteMethod = "DELETE";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profiles/show_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profiles/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.updateProfileImageEndPoint = 'bx_block_profile/profiles/upload_image';
exports.deleteProfileImageEndPoint = 'bx_block_profile/profiles/remove_image';
exports.addCertificateEndPoint = 'bx_block_profile/certificates';
exports.addFieldEndPoint = 'bx_block_profile/field_educators';
exports.addSectionEndPoint = 'bx_block_profile/sections';
exports.getAllProfileEndPoint = 'bx_block_profile/profiles';
exports.updateSingleEmployeeEndPoint = 'bx_block_profile/profiles';
exports.getGroupNameEndPoint = 'bx_block_profile/profiles/group_listing';
exports.getFilterGroupEndPoint = 'bx_block_profile/profiles/filter_profiles';
// Customizable Area End
