// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "./components/ApiCall";
import { Alert, Linking } from "react-native";

import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
export interface ItemData {
  id: "259",
  type: "note",
  attributes: {
    id: 259,
    note_content: "<div><a href=\"www.instagram.com\">www.instagram.com</a></div>",
    reference_id: 1,
    is_deleted: false,
    is_updated: false,
    created_at: "2023-06-07T11:53:01.383Z",
    updated_at: "2023-06-07T11:53:01.383Z",
    created_time: "11:53:01",
    created_date: "2023-06-07"
  },
}

export interface ResponseData {
  message: boolean;
  id: number,
  type: string,
  attributes: ObjectAttributes,
  data: []
}

interface ObjectAttributes {
  id: number,
  note_content: string,
  reference_id: number,
  is_deleted: false,
  is_updated: false,
  created_at: string,
  updated_at: string,
  created_time: string,
  created_date: string
}

export interface DeleteItem {
  attributes: {
    created_at: "",
    created_date: "",
    created_time: "",
    id: 96,
    is_deleted: false,
    is_updated: false,
    note_content: "",
    reference_id: 1,
    updated_at: ""
  },
  id: "",
  type: ""
}
export interface Image {
  id: number;
  url: string;
}

export interface NoteAttributes {
  id: number;
  title: string;
  note_content: string;
  account_id: number;
  role_id: string;
  preferred_name: string;
  course_id: number;
  is_deleted: boolean;
  is_updated: boolean;
  created_at: string;
  updated_at: string;
  images: Image[];
  created_time: string;
  created_date: string;
}
export interface IndNoteAttributes {
  id: number;
  title: string;
  note_content: string;
  account_id: number;
  role_id: string;
  preferred_name: string;
  course_id: number;
  is_deleted: boolean;
  is_updated: boolean;
  created_at: string;
  updated_at: string;
  images: Image[];
  created_time: string;
  created_date: string;
}

export interface Notes {
  id: string;
  type: string;
  attributes: NoteAttributes;
}
export interface Note {
  id: string;
  type: string;
  attributes: IndNoteAttributes;
}

export interface NotesData {
  data: Notes[];
}
export interface NoteDataResponse {
  data:Note | [];
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  notesList: [];
  isLoading: boolean;
  notesData: string;
  open:boolean;
  darkTheme:boolean;
  openNoteModal:boolean;
  openDialog:boolean;
  openImageModal:boolean;
  anchorEl: null | HTMLElement;
  uploadImage: File | null;
  uploadVideo: File | null;
  imagePreview: string;
  videoPreview: string;
  openMoreMenu:boolean;
  title:string;
  note:string;
  allNotes:NotesData;
  myNotes:NotesData;
  individualNote:NoteDataResponse;
  currentSection:string;
  openIndSection:boolean;
  enableEdit:boolean;
  editId:string;
}

interface SS {
  id: any;
}

export default class ProjectnotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getNotesListApiCallId: string = "";
  deleteNotesCallId: string = "";
  postProjectNotesAPIId:string="";
  getAllListNoteAPIId:string="";
  getMyListNoteAPIId:string="";
  getIndividualNoteAPIId:string="";
  editProjectNotesAPIId:string="";
  deleteProjectNoteAPId:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      notesList: [],
      isLoading: false,
      notesData: "",
      open:false,
      darkTheme:false,
      openNoteModal:false,
      openDialog:false,
      openImageModal:false,
      anchorEl: null,
      uploadImage: null,
      uploadVideo: null,
      imagePreview: '',
      videoPreview: '',
      openMoreMenu:false,
      title:"Add Note",
      note:'Add Note Description',
      allNotes:{data: [
        {
          id: '',
          type: 'note',
          attributes: {
            id: 0,
            title: '',
            note_content: '',
            account_id: 0,
            role_id: '',
            preferred_name: '',
            course_id: 0,
            is_deleted: false,
            is_updated: false,
            created_at: '',
            updated_at: '',
            images: [],
            created_time: '',
            created_date: '',
          },
        },
      ],},
      myNotes:{ 
        data: [
        {
          id: '',
          type: 'note',
          attributes: {
            id: 0,
            title: '',
            note_content: '',
            account_id: 0,
            role_id: '',
            preferred_name: '',
            course_id: 0,
            is_deleted: false,
            is_updated: false,
            created_at: '',
            updated_at: '',
            images: [],
            created_time: '',
            created_date: '',
          },
        },
      ],},
      individualNote:{
        data:{
        id: '',
        type: 'note',
        attributes: {
          id: 0,
          title: '',
          note_content: '',
          account_id: 0,
          role_id: '',
          preferred_name: '',
          course_id: 0,
          is_deleted: false,
          is_updated: false,
          created_at: '',
          updated_at: '',
          images: [],
          created_time: '',
          created_date: '',
       },
      },
    },
      currentSection:"all_notes",
      openIndSection:false,
      enableEdit:false,
      editId:"",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
      this.setEnableField()
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: this.doButtonPressed,
  };

  doButtonPressed() {
    let mesg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    mesg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(mesg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  async componentDidMount() {
    this.getAllListNotes();
    this.getMyListNotes();
  }

  showNotesModalUpdateState = (item: ItemData) => {
    this.props.navigation.navigate("NewProjectNotes", { data: item })
  }

  showNotesModalSetState = () => {
    this.props.navigation.navigate("NewProjectNotes")
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)

      )

      if (responseJson && !responseJson.errors && !responseJson.message) {
       await this.responseSucessCall(apiRequestCallId, responseJson);
      }
      else if (errorReponse) { this.setState({ isLoading: false }) }
    }
  }
  responseSucessCall = async (apiRequestCallId: string, responseJson: ResponseData) => {
    if (apiRequestCallId === this.getAllListNoteAPIId) {
      this.setState({
        allNotes:responseJson 
      })
    }
    if (apiRequestCallId === this.getMyListNoteAPIId) {
      this.setState({
        myNotes:responseJson
      })
    }
    if (apiRequestCallId === this.postProjectNotesAPIId){
      this.getAllListNotes();
    }
    if (apiRequestCallId === this.getIndividualNoteAPIId){
      this.setState({
        individualNote:responseJson,
      })
    }
    if (apiRequestCallId === this.editProjectNotesAPIId){
      this.setState({
        editId:"",
        enableEdit:false,
        openIndSection:false,
        openNoteModal:false,
      },()=>{this.getAllListNotes()})
    }
    if(apiRequestCallId === this.deleteProjectNoteAPId){
      this.setState({
        editId:"",
        enableEdit:false,
        openIndSection:false,
        openNoteModal:false,
      },()=>{this.getAllListNotes()})  
    }
  }


  getListOfNotes = async () => {
    this.getNotesListApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.createNoteApiEndPoint}?reference_id=1`,
    })
  }

  getListNotesApiSuccessCallBack = async (responseJson: ResponseData) => {
    this.setState({ notesList: responseJson?.data })
  }

  showNotesModalDeleteNotes = async (item: ItemData) => {
    const noteId = item.attributes.id;
    const bodyData = {
      reference_id: 1
    }
    this.deleteNotesCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: "DELETE",
      endPoint: `bx_block_projectnotes/notes/${noteId}`,
      body: bodyData
    });
  }

  deleteNotesApiSuccessCallBack = async (responseJson: ResponseData) => {
    await this.getListOfNotes()
  }

  handlePostProjectNote = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const attrs={
      "course_id":62,
      "title":this.state.title,
      "note":this.state.note,
      "images":this.state.uploadImage
    }
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postProjectNotesAPIId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.postProjectAPIEndpoint);
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify({note:attrs}));
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    console.log("JSON data",JSON.stringify(attrs),attrs,typeof this.state.note);
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(message.id, message);
  };
  handleEditProjectNote = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const attrs={
      "title":this.state.title,
      "note":this.state.note,
    }
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editProjectNotesAPIId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getIndividualAPIEndpoint}${this.state.editId}`);
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify({note:attrs}));
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    console.log("JSON data",JSON.stringify(attrs),attrs,typeof this.state.note);
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(message.id, message);
  };
  handleDeleteProjectNote = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteProjectNoteAPId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.deleteNoteAPIEndpoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(message.id, message);
  };
  getAllListNotes = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllListNoteAPIId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getAllNotesAPIEndpoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(message.id, message);
  };
  getMyListNotes = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getMyListNoteAPIId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getMyNotesAPIEndpoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(message.id, message);
  };
  getIndividualNotes = async (id:string) => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getIndividualNoteAPIId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getIndividualAPIEndpoint}${id}`);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(message.id, message);
  };
  handleMenu=()=>{
    this.setState({open:!this.state.open});
    localStorage.setItem('open',JSON.stringify(!this.state.open));
  }
  
  handleTheme=()=>{
    this.setState({darkTheme:!this.state.darkTheme})
  }
  handleNoteModal=()=>{
    this.setState({
      openNoteModal:true,
    })
  }
  closeNoteModal=()=>{
    this.setState({
      openNoteModal:false
    })
  }
  handleDialogModal=()=>{
    this.setState({
      openDialog:true,
    })
  }
  closeDialogModal=()=>{
    this.setState({
      openDialog:false
    })
  }
  handleImageModal=()=>{
    this.setState({
      openNoteModal:false,
      openImageModal:true,

    })
  }
  closeImageModal=()=>{
    this.setState({
      openImageModal:false,
      openNoteModal:true,
    })
  }
  handleMoreMenu=()=>{
    this.setState({
      openMoreMenu:true,
    })
  }
  closeMoreMenu=()=>{
    this.setState({
      openMoreMenu:false,
    })
  }
  handleNoteTitle=(event: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({
      title:event.target.value
    })
  }
  handleNoteDescription=(event:React.ChangeEvent<HTMLTextAreaElement>)=>{
    this.setState({
      note:event.target.value
    })
  }
  editPost=(id:string,newTitle:string,new_content:string,image:string="")=>{
    this.setState({
      title:newTitle,
      note:new_content,
      imagePreview:image,
      editId:id,
      openNoteModal:true,
      enableEdit:true
    })
  }
  uploadMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ anchorEl: null })
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      this.setState({ uploadImage: file, imagePreview: URL.createObjectURL(file) })
    }
    if (file && file.type.startsWith('video/')) {
      this.setState({ uploadVideo: file, videoPreview: URL.createObjectURL(file) })
    }
  }
  handleSectionChange=(curSection:string):void=>{
    this.setState({
      currentSection:curSection,
      openIndSection:false,
    })
  }
  openIndividualNote=(id:string)=>{
    this.getIndividualNotes(id);
    this.setState({
      openIndSection:true,
    })
  }
}
// Customizable Area End
