// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import ImagePicker from "react-native-image-crop-picker";
import { RichEditor } from "react-native-pell-rich-editor";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

export interface ItemType {
  id: string;
  type: string;
  attributes: {
    item: string;
    created_at: string;
    content_type: string;
  };
}
export interface ItemTypeFlatList {
  item: ItemType;
}

export interface BulletinType {
  "id": string;
  "type": string;
  "attributes": {
    "id": number;
    "name": string;
    "description": string;
    "body": string;
    "location": string;
    "account_id": number;
    "preferred_name": string;
    "role_id": string;
    "created_at": string;
    "updated_at": string;
    "images": [];
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  itemList: ItemType[];
  isModalVisible: boolean;
  isEditModal: boolean;
  editItemId: null | string;
  editItemData: ItemType | null;
  isCalenderOpen: boolean;
  currentDate: string;
  isLoading: boolean;
  themeStatus: boolean;
  menuStatus: boolean;
  totalPage: number;
  currentPage: number;
  anchorElNav: null | HTMLElement;
  bulletinModal: boolean;
  publishTypeKey: string;
  anchorElMenu: null | HTMLElement;
  uploadedImage: File | null;
  uploadedVideo: File | null;
  imagePreview: string;
  videoPreview: string;
  description: string;
  publishTypeModal: boolean;
  apiSuccessModal: boolean;
  deleteModal: boolean;
  bulletinList: BulletinType[];
  editBulletinKey: boolean;
  editBulletinId: number;
  deleteBulletinKey: boolean;
  successMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulletinBoardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  richText: React.RefObject<RichEditor>;
  getItemsAPICallId: string = "";
  editItemAPICallId: string = "";
  postItemAPICallId: string = "";
  deleteItemAPICallId: string = "";
  addBulletinApiCallId: string = "";
  getBulletinApiCallId: string = "";
  editBulletinApiCallId: string = "";
  deleteBulletinApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      itemList: [],
      isModalVisible: false,
      isEditModal: false,
      editItemId: null,
      editItemData: null,
      currentDate: "",
      isCalenderOpen: false,
      isLoading: false,
      themeStatus: false,
      menuStatus: false,
      totalPage: 1,
      currentPage: 1,
      anchorElNav: null,
      bulletinModal: false,
      publishTypeKey: "",
      anchorElMenu: null,
      uploadedImage: null,
      uploadedVideo: null,
      imagePreview: "",
      videoPreview: "",
      description: "",
      publishTypeModal: false,
      apiSuccessModal: false,
      deleteModal: false,
      bulletinList: [],
      editBulletinKey: false,
      deleteBulletinKey: false,
      editBulletinId: 1,
      successMessage: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.richText = React.createRef<RichEditor>();
    // Customizable Area End
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.setState({ isLoading: false });

    if (responseJson && !responseJson.errors) {
      if (apiRequestCallId === this.getItemsAPICallId) {
        this.setState({
          isLoading: false,
          isModalVisible: false,
          itemList: responseJson.data,
        });
      } else if (apiRequestCallId === this.postItemAPICallId) {
        this.setState({
          isLoading: false,
          isModalVisible: false,
          txtInputValue: "",
        });
        this.getItemsApi();
      } else if (apiRequestCallId === this.deleteItemAPICallId) {
        this.setState({ isLoading: false, isModalVisible: false });
        this.getItemsApi();
        this.showAlert("Success", responseJson.message);
      } else if (apiRequestCallId === this.editItemAPICallId) {
        this.getItemsApi();
        this.setState({
          editItemData: null,
          isEditModal: false,
          isModalVisible: false,
          editItemId: null,
          txtInputValue: "",
          isLoading: false,
          itemList: [],
        });
      }
      else {
        this.bulletinResponses(message);
      }
    } else {
      this.setState({ isLoading: false, bulletinModal: false });
      if (responseJson.errors[0].token) {
        toast.error(responseJson.errors[0].token, { containerId: 'A' })
      }
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (textValue: string) => {
      this.setState({ txtInputValue: textValue });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let messageData = new Message(getName(MessageEnum.AccoutLoginSuccess));
    messageData.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(messageData);
  }

  // web events
  setInputValue = (textValue: string) => {
    this.setState({ txtInputValue: textValue });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  openModal = () => this.setState({ isModalVisible: true, isLoading: true });

  openEditModal = (item: ItemType, currentItemId: string) => {
    this.setState({
      isEditModal: true,
      editItemData: item,
      isModalVisible: true,
      editItemId: currentItemId,
      txtInputValue: item.attributes.item,
      isLoading: true,
    });
  };

  uploadPhoto = async () => {
    try {
      const selectedImage = await ImagePicker.openPicker({});
      const imageData = {
        uri: selectedImage.path,
        name: selectedImage.modificationDate,
        type: selectedImage.mime,
      };
      this.addItemApi({ type: "image", imageData });
    } catch (errorMessage) {
      this.showAlert(configJSON.errorLable, JSON.stringify(errorMessage));
    }
  };

  returnDate = (dateString: string) => {
    const currentDate = new Date(dateString);
    const dayDate = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const months = configJSON.listOfMonths;
    const monthName = months[monthIndex];
    return `${dayDate} ${monthName} ${year}`;
  };

  async componentDidMount() {
    this.getBulletin()
    let menuState:any= localStorage.getItem('open');
    if(menuState!=null){
     this.setState({menuStatus:JSON.parse(menuState)})
    }
    let currentDate = new Date().toISOString().split("T")[0];
    this.setState({ currentDate }, () => this.getItemsApi());
    this.btnShowHideProps.onPress();
  }

  removeHTML = (textString: string) =>
    textString
      .replace(/<[^>]*>/g, "")
      .replace(/&([^\s&;]+);/g, "")
      .trim();

  saveBtnPress = () => {
    const { txtInputValue } = this.state;
    if (this.removeHTML(txtInputValue).length > 0) {
      if (this.state.isEditModal) {
        this.editItemApi();
      } else {
        this.addItemApi({ type: "text" });
      }
    }
  };

  getItemsApi = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getItemsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getItemsAPiEndPoint + "?date=" + this.state.currentDate
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteItemApi = (deleteItemId: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteItemAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mainAPiEndPoint + deleteItemId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  editItemApi = () => {
    const header = {
      "Content-Type": configJSON.formDataAPIType,
    };
    let formData = new FormData();

    formData.append("item", this.state.txtInputValue);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editItemAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mainAPiEndPoint + this.state.editItemId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addItemApi = (itemData: {
    type: string;
    imageData?: {
      uri: string;
      name: string | undefined;
      type: string;
    };
  }) => {
    const header = {
      "Content-Type": configJSON.formDataAPIType,
    };

    let formData = new FormData();

    if (itemData.imageData) {
      formData.append("content_type", "image");
      formData.append("image", JSON.parse(JSON.stringify(itemData.imageData)));
    } else {
      formData.append("content_type", "text");
      formData.append("item", this.state.txtInputValue);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postItemAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mainAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onCalendarPress = (selectedDay: { dateString: string }) => {
    this.setState(
      { currentDate: selectedDay.dateString, isCalenderOpen: false },
      () => this.getItemsApi()
    );
  };

  openCalendar = () => this.setState({ isCalenderOpen: true });

  bulletinResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.addBulletinApiCallId) {
      this.setState({
        bulletinModal: false,
        apiSuccessModal: true,
        successMessage: 'added',
        description: ""
      }, () => this.getBulletin());
    } else if (apiRequestCallId === this.getBulletinApiCallId) {
      this.setState({ bulletinList: responseJson.data })
    }
    else if (apiRequestCallId === this.editBulletinApiCallId) {
      this.setState({
        bulletinModal: false,
        apiSuccessModal: true,
        successMessage: 'edited',
        description: ""
      }, () => this.getBulletin());
    } else if (apiRequestCallId === this.deleteBulletinApiCallId) {
      this.setState({
        deleteModal: false,
        apiSuccessModal: true,
        successMessage: 'deleted'
      }, () => this.getBulletin());
    }
  }

  toggleMenu = () => {
    this.setState({ menuStatus: !this.state.menuStatus })
    setStorageData('open', JSON.stringify(!this.state.menuStatus))
  }

  toggleTheme = () => {
    this.setState({ themeStatus: !this.state.themeStatus })
    setStorageData('darkTheme', JSON.stringify(!this.state.themeStatus))
  }

  changePageNumber = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value })
  }

  changeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ description: event.target.value })
  }

  OpenNavMenu = (event: React.MouseEvent<HTMLElement>, id: number) => {
    this.setState({ anchorElNav: event.currentTarget, editBulletinId: id });
  };

  closeNavMenu = () => {
    this.setState({ anchorElNav: null })
  }

  openBulletinModal = () => {
    this.setState({ bulletinModal: true })
  }

  closeBulletinModal = () => {
    this.setState({ bulletinModal: false, deleteModal: false, description: "" })
  }

  changePublishType = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ publishTypeKey: event.target.value })
  }

  uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState({ uploadedImage: file, anchorElMenu: null, imagePreview: URL.createObjectURL(file) });
      toast.success("Uploaded Image Saved")
    }
  }

  uploadVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState({ uploadedVideo: file, anchorElMenu: null, videoPreview: URL.createObjectURL(file) });
      toast.success("Uploaded Video Saved")
    }
  }

  apiCall = async (data: { [key: string]: string }) => {
    const { method3, endPoint3, body3, type3, contentType3 } = data;
    let apiBody = body3;
    if (type3 === '') {
      apiBody = JSON.stringify(body3);
    }
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": contentType3,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint3
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.baseURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method3
    );

    body3 &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  addNewBulletin = async () => {
    let values = [];
    let obj = this.state.uploadedImage;
    values.push(obj);
    let body = {}
    body = {
      name: 'Add bulletin',
      description: this.state.description,
      images: values
    }
    this.addBulletinApiCallId = await this.apiCall({
      method3: configJSON.exampleAPiMethod,
      contentType3: configJSON.exampleApiContentType,
      endPoint3: configJSON.createBulletinEndPoint,
      body3: JSON.stringify(body)
    });
  }

  getBulletin = async () => {
    this.getBulletinApiCallId = await this.apiCall({
      method3: configJSON.validationApiMethodType,
      contentType3: configJSON.exampleApiContentType,
      endPoint3: configJSON.createBulletinEndPoint,
    });
  }

  editBulletin = async () => {
    this.setState({
      editBulletinKey: true,
      bulletinModal: true,
      anchorElNav: null,
    })
    this.state.bulletinList?.map((item) => {
      if (item.attributes.id === this.state.editBulletinId) {
        this.setState({ description: item.attributes.description })
      }
    })
  }

  editBulletinApi = async () => {
    let values1 = [];
    let obj1 = this.state.uploadedImage;
    values1.push(obj1);
    let body = {}
    body = {
      name: 'Add bulletin',
      description: this.state.description,
      images: values1
    }
    this.editBulletinApiCallId = await this.apiCall({
      method3: configJSON.PUT,
      contentType3: configJSON.exampleApiContentType,
      endPoint3: `${configJSON.createBulletinEndPoint}/${this.state.editBulletinId}`,
      body3: JSON.stringify(body)
    });
  }

  deleteBulletin = () => {
    this.setState({
      deleteBulletinKey: true,
      anchorElNav: null,
      deleteModal: true, 
    })
    this.state.bulletinList?.map((element) => {
      if (element.attributes.id === this.state.editBulletinId) {
        this.setState({ description: element.attributes.description })
      }
    })
  }

  deleteBulletinApi = async () => {
    this.deleteBulletinApiCallId = await this.apiCall({
      method3: configJSON.DELETE,
      contentType3: configJSON.exampleApiContentType,
      endPoint3: `${configJSON.createBulletinEndPoint}/${this.state.editBulletinId}`,
    });
  }

  openPublishTypeModal = () => {
    this.setState({ publishTypeModal: true })
  }

  closePublishTypeModal = () => {
    this.setState({ publishTypeModal: false, apiSuccessModal: false, publishTypeKey: "" })
  }
  // Customizable Area End
}

// Customizable Area End
