import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import moment from 'moment'; 
interface CourseContent {
  id: string;
  type: "course_content";
  attributes: {
    course_id: number;
    title: string;
    lesson_name: string;
    lesson_number: number;
    video: string | null;
    document: string | null;
  };
}

interface CourseAttributes {
  course_name: string;
  course_description: string;
  category: string | null;
  sub_category: string | null;
  sub_sub_category: string | null;
  language: string;
  price: string;
  level: string;
  tags: string;
  duration: string;
  year: string;
  is_assigned: boolean;
  is_completed: boolean;
  profile_id: number;
  role_id: string;
  preferred_name: string;
  media_type: string;
  publish_type: string;
  due_date: string | null;
  course_contents_count: number;
  image: string;
  video_url: string | null;
  document_url: string | null;
}

interface Course {
  id: string;
  type: "course";
  attributes: CourseAttributes;
  relationships: {
    course_contents: {
      data: CourseContent[];
    };
  };
  included: CourseContent[];
}

interface StudentCourseAssignmentAttributes {
  id: number;
  created_at: string;
  updated_at: string;
  status: string;
  progress_percentage: string;
  expiration_date: string;
  group: string;
  course: {
    data: Course;
    included: CourseContent[];
  };
  profile: {
    data: Profile;
  };
}

interface StudentCourseAssignment {
  id: string;
  type: "student_course_assignment";
  attributes: StudentCourseAssignmentAttributes;
}

interface ProfileAttributes {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
  date_of_birth: string;
  preferred_name: string;
  role_id: string;
  full_phone_number: string;
  employee_number: string | null;
  location: string | null;
  company_reference: string | null;
  completed_course: string | null;
  account_id: number;
  image: string | null;
  group_id: number;
  group_name: string;
  company_id: number;
  company_name: string;
  phone_number_verified: boolean;
  selected_theme: string;
  font_size: string;
}

interface Profile {
  id: string;
  type: "profile";
  attributes: ProfileAttributes;
}

interface CourseData {
  data: StudentCourseAssignment[];
}

interface ProgressCoursesData {
  data: any[]; // Adjust this type based on your actual structure
}

export interface StudentCourseData {
  progress_course_count: number;
  completed_course_count: number;
  average_score: string;
  watch_time: string;
  certificate_count: string;
  progress_courses: ProgressCoursesData;
  assigned_courses: CourseData;
}

export interface TeacherCourseData {
    uploaded_course_count: number;
    assigned_course_count: number;
    completed_course_percentage: number;
    active_enrollment: number;
    courses: {
      data: any[];
    };
    courses_by_month: any[];
  }

  interface TeamMembers {
    data: any[]; // Replace 'any' with a specific type if you know the structure of team members
  }
  
  interface Goals {
    data: any[]; // Replace 'any' with a specific type if you know the structure of goals
  }
  
  interface AverageScorePerCourses {
    [courseName: string]: number;
  }
  
  export interface ManagerData {
    team_members_count: number;
    learning_goal_count: number;
    average_score: string;
    average_completion: string;
    average_certificate: string;
    team_members: TeamMembers;
    goals: Goals;
    average_score_per_courses: AverageScorePerCourses;
  }

interface ProfileData  {
  id: string;
  type: string;
  attributes: ProfileAttributes;
};

interface ProfileResponse  {
  data: ProfileData;
};
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  open:boolean;
  darkTheme:boolean;
  progressCourseCount: number,
  completedCourseCount: number,
  averageScore: string,
  watchTime: string,
  certificateCount: string,
  studentDashboardIndexData:StudentCourseData;
  teacherDashboardIndexData:TeacherCourseData;
  dashboardIndexData:StudentCourseData | TeacherCourseData | ManagerData;
  managerDashboardIndexData:ManagerData;
  profileData:ProfileData;

  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiDashboardGETCallId:string="";
  apiProfileGETCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      open:false,
      darkTheme:false,
      progressCourseCount: 0,
      completedCourseCount: 0,
      averageScore: "0",
      watchTime: "0h 0Min.",
      certificateCount: "0",
      studentDashboardIndexData: {
      "progress_course_count": 0,
      "completed_course_count": 0,
      "average_score": "0",
      "watch_time": "0h 0Min.",
      "certificate_count": "0",
      "progress_courses": {data:[]},
      "assigned_courses":{data:[]}},
      teacherDashboardIndexData:{
        "uploaded_course_count": 0,
        "assigned_course_count": 0,
        "completed_course_percentage": 0,
        "active_enrollment": 0,
        "courses": { data: [],},
        "courses_by_month": [],},
        dashboardIndexData:{
          "progress_course_count": 0,
          "completed_course_count": 0,
          "average_score": "0",
          "watch_time": "0h 0Min.",
          "certificate_count": "0",
          "progress_courses": {data:[]},
          "assigned_courses":{data:[]}},
          managerDashboardIndexData: {
            team_members_count: 0,
            learning_goal_count: 0,
            average_score: "",
            average_completion: "",
            average_certificate: "",
            team_members: {
              data: [],
            },
            goals: {
              data: [],
            },
            average_score_per_courses: {},
          },
      profileData: {
        id: "",
        type: "profile",
        attributes: {
          id: 0,
          first_name: null,
          last_name: null,
          email: "",
          date_of_birth: "",
          preferred_name: "",
          role_id: "",
          full_phone_number: "",
          employee_number: null,
          location: null,
          company_reference: null,
          completed_course: null,
          account_id: 0,
          image: null,
          group_id: 0,
          group_name: "",
          company_id: 0,
          company_name: "",
          phone_number_verified: false,
          selected_theme: "",
          font_size: "",
        },
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getUserDashboardData();
    this.getUserProfileData();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    // runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
        else if (webApiRequestCallId === this.apiProfileGETCallId) {
          this.setState({
            profileData: webResponseJson.data,
            errorMsg: "",
            loading: false
          });
        } else if (webApiRequestCallId === this.apiDashboardGETCallId) {
          this.setWebResponseData(webResponseJson);
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  timeConvert=(date:string)=>{
    return moment(date).format('MM-DD-YYYY');
  }
  setWebResponseData=(webResponseJson:any)=>{
    if(this.state.profileData.attributes.role_id==="student"){
      this.setState({
        studentDashboardIndexData: webResponseJson,
        errorMsg: "",
        loading: false
      });
    } else if(this.state.profileData.attributes.role_id==="educator"){
      this.setState({
        teacherDashboardIndexData: webResponseJson,
        errorMsg: "",
        loading: false
      });
    }
    else if(this.state.profileData.attributes.role_id==="supervisor/manager"){
      this.setState({
        managerDashboardIndexData: webResponseJson,
        errorMsg: "",
        loading: false
      });
    }
  }
  getUserDashboardData=()=>{
    const webHeader = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardGETCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }
  getUserProfileData=()=>{
    const webHeader = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileGETCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.profileGetURL
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  handleMenu=()=>{
    this.setState({open:!this.state.open});
    localStorage.setItem('open',JSON.stringify(!this.state.open));
  }
  
  handleTheme=()=>{
    this.setState({darkTheme:!this.state.darkTheme})
  }
  transformData = (inputData:any) => {
    return Object.entries(inputData["average_score_per_courses"]).map(([name, averageScore]) => ({
      name,
      averageScore
    }));
  };
  // Customizable Area End
}
