Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPatchMethod =  "PATCH";
exports.httpPutMethod = "PUT";
exports.getNotificationAPIEndPoint = "notifsettings/notification_settings";
exports.updateNotificationAPIEndPoint = "notifsettings/notification_settings/";
exports.updateNotificationGroupAPIEndPoint = "notifsettings/groups/";
exports.updateNotificationSubGroupAPIEndPoint = "notifsettings/subgroups/";
exports.getNotificationDataEndPoint="bx_block_settings/setting_notifications";
exports.notificationCategoriesDataEndPoint="bx_block_settings/notification_categories";



exports.notificationApiContentType = "application/json";

// Customizable Area End