import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Modal,
  Select,
  FormControl,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import {
  Close as CloseIcon,
} from "@material-ui/icons";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CustomProfileHeaderController, {
  Props,
  configJSON,
} from "./CustomProfileHeaderController";

export default class CustomProfileHeader extends CustomProfileHeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.imageRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  renderButton(imageExists: boolean, isImageSelected: boolean) {
    if (!imageExists || !isImageSelected) {
      return (
        <Button
          variant="contained"
          component="label"
          data-test-id="chooseImageButton"
          disabled={this.state.selectedAccount === null}
        >
          {configJSON.chooseImageButtonTitle}
          <input
            type="file"
            accept="image/*"
            onChange={(e: React.ChangeEvent<HTMLInputElement> ) => this.openImagePicker(e)}
            hidden
            data-test-id="fileInput"
          />
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        component="label"
        data-test-id="saveImage"
        onClick={() => this.uploadImage()}
      >
        {configJSON.saveImageButtonTitle}
      </Button>
    );
  }

  renderCropModal() {
    const { crop, modalOpen, imgSrc } = this.state;
    return (
      <Modal
        open={modalOpen}
        style={webStyle.cropModalContainer}
        data-test-id="modal"
        {...this.cropModalWebProps}
      >
        <Box data-test-id="cropModal" style={webStyle.cropModal}>
          <Typography variant="subtitle1" component="div">
            {configJSON.cropImageModalTitle}
          </Typography>
          <ReactCrop
            crop={crop}
            style={{ height: window?.innerHeight * 0.7 }}
            {...this.cropPickerWebProps}
            data-test-id="reactCrop"
          >
            <img
              src={imgSrc}
              style={{ height: window.innerHeight * 0.7 }}
              ref={this.imageRef}
              data-test-id="preview"
              {...this.cropImageWebProps}
            />
          </ReactCrop>
          <Button
            variant="contained"
            component="label"
            data-test-id="submitButton"
            {...this.cropSubmitButtonWebProps}
          >
            {configJSON.cropImageButtonTitle}
          </Button>
        </Box>
      </Modal>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { image, accountList, isUpload, isImageSelect } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box style={webStyle.boxUpperSpace}>
            {isUpload && (
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    data-test-id="update-alert"
                    onClick={() => {
                      this.setIsUpload(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              >
                {configJSON.profileUpdated}
              </Alert>
            )}
            <Box  style={webStyle.boxUpperSpace}>
              <FormControl fullWidth>
                <InputLabel>{configJSON.accounDropDowntTitle}</InputLabel>
                <Select
                  id="select-account"
                  data-test-id="select-account"
                  label={configJSON.accounDropDowntTitle}
                  onChange={this.handleAccountChange}
                >
                  {accountList?.map((item: { label?: string, value?: string }, index: number) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6">{configJSON.labelTitleText}</Typography>
              <Typography variant="subtitle1" component="div">
                {configJSON.labelBodyText}
              </Typography>
              {this.renderCropModal()}
              {image?.base64 && (
                <img
                  src={image?.base64}
                  style={webStyle.previewImage}
                  data-test-id="imageSelect"
                />
              )}
              {this.renderButton(!!image?.base64, isImageSelect)}
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  cropModalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as "column",
  },
  cropModal: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as "column",
  },
  previewImage: {
    width: 320,
    height: 180,
    margin: 20,
  },
  boxUpperSpace: {
    marginTop: 10
  },
};
// Customizable Area End
