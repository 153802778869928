import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Typography,
  Modal,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ICourse, IStudent } from "./types/types";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
import PtBasicUserAllCoursesScreenController, {
  Props,
  configJSON,
} from './PtBasicUserAllCoursesScreenController';

export default class PtBasicUserAllCoursesScreen extends PtBasicUserAllCoursesScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAvailableCourses = () => {
    const { availableCourses, signedUpCourses } = this.state;

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{configJSON.courseNameText}</TableCell>
              <TableCell>{configJSON.descriptionText}</TableCell>
              <TableCell>{configJSON.registrationStatusText}</TableCell>
              <TableCell>{configJSON.studentListText}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availableCourses?.map((course: ICourse, index: number) => {
              const isSignedUp = signedUpCourses.some(
                (signedUpCourse: ICourse) =>
                  signedUpCourse.attributes.id === course.attributes.id
              );
              const signedUpCourse = signedUpCourses?.find(
                (signedUpCourse: ICourse) =>
                  signedUpCourse.attributes.id === course.attributes.id
              );
              const signedUpCourseApproved =
                signedUpCourse?.attributes.approved;

              return (
                <TableRow key={`availableCourse-${index}`}>
                  <TableCell>{course.attributes.name}</TableCell>
                  <TableCell>{course.attributes.description}</TableCell>
                  <TableCell>
                    {course.attributes.enabled ? (
                      isSignedUp ? (
                        signedUpCourseApproved ? (
                          <Typography>{configJSON.registeredText}</Typography>
                        ) : (
                          <Typography>
                            {configJSON.awaitingApprovalText}
                          </Typography>
                        )
                      ) : (
                        <Button
                          data-test-id={`registerForCourseButton${index}`}
                          title={"Register for Course"}
                          variant="contained"
                          onClick={() =>
                            this.registerForCourse(
                              this.state.studentId,
                              course.attributes.id
                            )
                          }
                        >
                          {configJSON.registerForCourseText}
                        </Button>
                      )
                    ) : (
                      <Typography>{configJSON.disabledText}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {course.attributes.enabled &&
                      isSignedUp &&
                      signedUpCourseApproved && (
                        <Button
                          data-test-id={`viewStudentsForCourseButton${index}`}
                          title={"View Students for Course"}
                          variant="contained"
                          onClick={() =>
                            this.setViewStudentsForCourseModalOpen(
                              course.attributes.id
                            )
                          }
                        >
                          {configJSON.viewStudentListText}
                        </Button>
                      )}
                    {this.renderViewStudentsForCourseModal()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  };

  renderViewStudentsForCourseModal = () => {
    const { studentList } = this.state;

    return (
      <Modal
        onClose={this.hideStudentsForCourseModalOpen}
        open={this.state.viewStudentsForCourseModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={webStyle.modal}>
          {studentList?.map((student: IStudent, index: number) => (
            <Typography>
              {configJSON.nameText}
              {student?.attributes?.account_id}
            </Typography>
          ))}
          <Box>
            <Button
              data-test-id={"btnCloseModal"}
              onClick={() => {
                this.hideStudentsForCourseModalOpen();
              }}
            >
              <Typography>{configJSON.closeText}</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Box>{this.renderAvailableCourses()}</Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  title: {},
  modal: {
    backgroundColor: "lightblue",
  },
};
// Customizable Area End
