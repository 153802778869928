export const funnel = require("../assets/funnel.png");
export const searchAgain = require("../assets/searchAgain.png");
export const search = require("../assets/search.png");
export const delete_warning = require("../assets/delete_Warning.png");
export const bg = require("../assets/bg.jpeg");
export const lightMail = require("../assets/lightMail.png");
export const lightBell = require("../assets/lightBell.png");
export const darkBell = require("../assets/darkBell.png");
export const darkMail = require("../assets/darkMail.png");


