Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.InsertApiMethodType = "POST";
exports.DeleteApiMethodType = "DELETE";
exports.validationApiMethodTypeUpdate = "PUT";
exports.exampleAPiEndPoint = "bx_block_certification_tracking/certificates";
exports.exampleAPiMethod = "GET";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.deleteApiMethodType = "DELETE";
exports.employeesURLEndPoint = "bx_block_employee/employees";
exports.employeesCertificateTrackingURLEndPoint =
  "bx_block_employee/employees_certificate";
exports.employeesCertificate = "employee_certificates";
exports.certificatesURLEndPoint =
  "bx_block_certification_tracking/certificates";
exports.employeesCertificateURLEndPoint =
  "bx_block_certification_tracking/employee_certificates";
exports.loginURLEndPoint = "bx_block_login/logins";
exports.departmentURLEndPoint = "bx_block_organisations/departments";
exports.designationURLEndPoint = "bx_block_organisations/designations";

exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CertificationTracking";
exports.labelBodyText = "CertificationTracking Body";

exports.btnExampleTitle = "CLICK ME";

exports.labelEmployeeName = "Employee Name";
exports.labelEmployeeTitle = "Employee Title";
exports.labelEmployeeDesignation = "Designation";
exports.labelEmployeeDepartment = "Department";
exports.labelEmployeeActive = "Active";
exports.labelEmployeeStatus = "Status";
exports.labelEmployeePicture = "Employee Picture";
exports.labelEmployeeThumbnail = "Employee Thumbnail";

exports.errorTitle = "Error!";
exports.errorAllFieldsAreMandatory = "Name, Designation are mandatory.";
exports.mappingFieldMandatory = "Employee, Certificate, Status are mandatory.";

exports.titleCertificateOfEmployeeList = "List Certificate of Employees";
exports.titleEmployeeList = "List of Employees";
exports.titleCertificateList = "List of Certificates";
exports.titleEmployeeCertificateMappingStatusList = "List of Status";
exports.titleActiveStatusList = "List of Active Status";
exports.titleEmployeeList = "List of Employees";
exports.titleDepartmentList = "List of Department";
exports.titleDesignationList = "List of Designation";

exports.labelEmployee = "Employee";
exports.labelCertificate = "Certificate";
exports.labelStatus = "Status";
exports.labelDate = "Date";
exports.labelValidityDate = "Validity Date";
exports.labelActive = "Active";

exports.labelDelete = "Delete";
exports.labelUpdate = "Update";
exports.labelCreate = "Create";
exports.labelSelect = "Select";

exports.addNewEmployee = "Create Employee";
exports.addNewEmployeeCertificationMapping = "Create Employee-Cert.";

exports.loginEmail = "buraktest@gmail.com";
exports.loginPassword = "123456";
exports.getGroupNamesEndPoint = 'bx_block_profile/profiles/group_listing';
exports.getCompanyNamesEndPoint = 'bx_block_profile/profiles/company_listing';
exports.getCourseNamesEndPoint = 'bx_block_profile/courses';
exports.getAssignedCoursesEndPoint = 'bx_block_profile/courses/assigned_list';
exports.getCompletedCourseEndPoint = 'bx_block_profile/courses/completed_assigned_courses';
exports.getRoleEndPoint = 'bx_block_profile/courses/user_role';
exports.searchListEndPoint = 'bx_block_profile/courses/search_list';
// Customizable Area End
