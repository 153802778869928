import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  checkedActionBar: boolean;
  checkedActionBarText: string;
  notificationData: any;
  token: string;
  data:any;
  titleData:any;
  togglePush:boolean;
  toggleEmail:boolean;
  open:boolean;
  darkTheme: boolean;
  titleId:number;
  selectedDay: string;
  dropdownOpen: boolean;
  dayDropdown: number| null;
  timeZone: string;
  ct_Timezone: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NotificationsettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  notificationAPICallId: any;
  setNotificationAPICall: any;
  setNotificationGroupAPICall: any;
  setNotificationSubGroupAPICall: any;
  notificationDataAPICallId:any;
  updatenotificationDataAPICallId:any;
  allNotificationCategoriesApiCallId:any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      checkedActionBar: false,
      checkedActionBarText: configJSON.notificationGroup1,
      notificationData: [],
      token: "",
      data:[],
      titleData:[],
      togglePush:false,
      toggleEmail:false,
      open:false,
      darkTheme:false,
      titleId:21,
      selectedDay:"Monday",
      dropdownOpen: false,
      dayDropdown: null,
      timeZone: "1:30 AM",
      ct_Timezone: "2:30 AM"
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getNotificationData() 
    this.chooseTheme()
  
    let menuState:any= localStorage.getItem('open');
    if(menuState!=null){
     this.setState({open:JSON.parse(menuState)})
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson?.data) {
        if (apiRequestCallId === this.notificationAPICallId) {
          this.setState({ notificationData: responseJson.data });
        }
        if (apiRequestCallId === this.notificationDataAPICallId) {
          this.setState({titleId:responseJson.data[0].id})
          this.setState({titleData: responseJson.data})
          this.setState({data: responseJson.included});
          this.setState({togglePush:responseJson.data[0].attributes.push})
          this.setState({toggleEmail:responseJson.data[0].attributes.email})
          this.setState({timeZone: responseJson.data[0]?.attributes.ct_timezone})
        }
        if(apiRequestCallId === this.updatenotificationDataAPICallId) {
          this.getNotificationData()
        }
        if(apiRequestCallId === this.allNotificationCategoriesApiCallId){
          this.getNotificationData()
        }
        runEngine.debugLog("API Recived", responseJson.data);
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ notificationData: this.state.notificationData });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  getNotificationDataRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area Start

  chooseTheme = async () => {
    const mode = await localStorage.getItem("darkMode");
    if (mode === 'true') {
      this.setState({ darkTheme: true })
    } else {
      this.setState({ darkTheme: false })
    }
  }


  handleMenu=()=>{
    this.setState({open:!this.state.open});
    localStorage.setItem('open',JSON.stringify(!this.state.open));
  }
  
  handleTheme=()=>{
    this.setState({darkTheme:!this.state.darkTheme})
  }
  setMainCheckBox(item: any) {
    var data = {
      title: item.attributes.title,
      description: item.attributes.description,
      state: item.attributes.state === "active" ? "inactive" : "active"
    };
    this.postNotificationDataRequest(
      configJSON.updateNotificationAPIEndPoint + `${item.id}`,
      data,
      "setNotification"
    );
  }

  setGroupCheckBox(index: number, groupIndex: number, groupitem: any) {
    var data = {
      group_type: groupitem.attributes.group_type,
      group_name: groupitem.attributes.group_name,
      state: groupitem.attributes.state === "active" ? "inactive" : "active",
      notification_setting_id: groupitem.attributes.notification_setting_id
    };
    this.postNotificationDataRequest(
      configJSON.updateNotificationGroupAPIEndPoint + `${groupitem.id}`,
      data,
      "setGroupNotification"
    );
  }

  setSubGroupCheckBox = (subGroupItem: any) => {
    let data = {
      subgroup_type: subGroupItem.attributes.subgroup_type,
      subgroup_name: subGroupItem.attributes.subgroup_name,
      state: subGroupItem.attributes.state === "active" ? "inactive" : "active",
      notification_group_id: subGroupItem.attributes.notification_group_id
    };

    this.postNotificationDataRequest(
      configJSON.updateNotificationSubGroupAPIEndPoint + `${subGroupItem.id}`,
      data,
      "setSubGroupNotification"
    );
  };

  postNotificationDataRequest = (
    endPointURL: string,
    data: any,
    type: string
  ): boolean => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (type === "setNotification") {
      this.setNotificationAPICall = requestMessage.messageId;
    } else if (type === "setGroupNotification") {
      this.setNotificationGroupAPICall = requestMessage.messageId;
    } else if (type === "setSubGroupNotification") {
      this.setNotificationSubGroupAPICall = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  getNotificationData = () => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.notificationDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleUpdateNotification = (payload:any) => {
    this.setState({timeZone:payload.ct_timezone})
 
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: localStorage.getItem("authToken")
    };
    const body ={
      "setting_notification": {
        ...payload,
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatenotificationDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationDataEndPoint +`/${this.state.titleId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    
  toggledButton = (item: any,payload:any) => {
    
    this.setState({ct_Timezone: payload.ct_timezone})
  const header = {
    "Content-Type": configJSON.notificationApiContentType,
    token: localStorage.getItem("authToken")
  };
  const body ={
    "notification_category": {
    ...payload,
    }
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.allNotificationCategoriesApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.notificationCategoriesDataEndPoint +`/${item.id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPutMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

 handleDaySelection = (payload:any) => {
  this.handleUpdateNotification(payload)
  this.setState({dropdownOpen: ! this.state.dropdownOpen})
};

handleCategoriesDay =(item:any,payload:any)=>{
  this.toggledButton(item,payload)
  this.setState({dayDropdown: null})
}

handleClick=(id:any)=>{ 
  this.setState({dayDropdown: this.state.dayDropdown==id ? null :id})
 
}
  // Customizable Area End
}
