// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  styled,
  Menu,
  MenuItem,
  TextareaAutosize,
  RadioGroup,
  FormControlLabel,
  Radio
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider } from "@material-ui/core/styles";
import { BackButton, NextButton, darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import Loader from "../../../components/src/Loader.web";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { MainContainer, HeadDiv, DotSection } from "../../notifications/src/Notifications.web";
import { editIcon, deleteIcon, alarmImage } from "../../customisableuserprofiles/src/assets";
import { FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import { Pagination } from "@material-ui/lab";
import MoreVertOutlined from "@material-ui/icons/MoreVertOutlined";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import InsertLinkOutlinedIcon from '@material-ui/icons/InsertLinkOutlined';
import { ToastContainer } from "react-toastify";
import { All, darkVerify, group, lightVerify, specific } from "../../postcreation/src/assets";
import { RadioDiv, RadioOptionHead, RadioOptionSubHead } from "../../postcreation/src/PostCreation.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// Customizable Area End

import BulletinBoardController, {
  Props,
  configJSON,
} from "./BulletinBoardController";

export default class BulletinBoard extends BulletinBoardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  BulletinModal = () => {
    const open = Boolean(this.state.anchorElMenu);
    return <FieldModal
      data-test-id='addBulletinModal'
      open={this.state.bulletinModal}
      onCancel={this.closeBulletinModal}
      title={
        <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: 'row' }}>
          <ModalHeading variant="body1" style={{ textAlign: 'left' }}>{this.state.editBulletinKey ? "Edit" : 'add'} post</ModalHeading>
          <PublishDiv data-test-id="openPublishTypeModal" onClick={this.openPublishTypeModal}>{this.state.publishTypeKey}<KeyboardArrowDownIcon /></PublishDiv>
        </ div>}
      centered
      closable={false}
      footer={null}
      destroyOnClose
      width={832}
      style={{ height: '480px' }}
    >
      <div style={{ padding: '10px 30px' }}>
        <DescriptionField data-test-id='addDescription' onChange={this.changeDescription} value={this.state.description} />
        <AttachmentDiv>
          <InsertLinkOutlinedIcon />
          <span style={{ margin: '0px 20px' }}>@</span>
          <span data-test-id='openUploadMenu' onClick={(event: React.MouseEvent<HTMLInputElement>) => this.setState({ anchorElMenu: event.currentTarget })}><CloudUploadOutlinedIcon /></span>
          <Menu
            id="basic-menu"
            open={open}
            anchorEl={this.state.anchorElMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            onClose={() => this.setState({ anchorElMenu: null })}
            style={{ top: '30px', left: '10px' }}
          >
            <MenuItem>
              <MenuTxt>
                Select
              </MenuTxt>
            </MenuItem>
            <MenuItem>
              <CloudUploadOutlinedIcon />
              <label htmlFor="uploadImage" className="profile-upload-btn" data-test-id="profile-upload-btn">
                <input
                  data-test-id="uploadImage"
                  id="uploadImage"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={this.uploadImage}
                />
                <MenuTxt>
                  Image
                </MenuTxt>
              </label>
            </MenuItem>
            <MenuItem>
              <InsertLinkOutlinedIcon />
              <label htmlFor="uploadVideo" className="profile-upload-btn" data-test-id="profile-upload-btn">
                <input
                  data-test-id="uploadVideo"
                  id="uploadVideo"
                  type="file"
                  style={{ display: "none" }}
                  accept="video/*"
                  onChange={this.uploadVideo}
                />
                <MenuTxt>
                  Video
                </MenuTxt>
              </label>
            </MenuItem>
          </Menu>
        </AttachmentDiv>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <StatusButton>Select Status</StatusButton>
          <SaveButton
            data-test-id="addBulletinButton"
            onClick={this.state.editBulletinKey ? this.editBulletinApi : this.addNewBulletin}
          >
            {this.state.editBulletinKey ? 'Save' : 'Add'}
          </SaveButton>
        </div>
      </div>
      <ToastContainer />
    </FieldModal>
  }

  publishTypeModal = () => (
    <FieldModal
      open={this.state.publishTypeModal}
      onCancel={this.closePublishTypeModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h1">Publish</ModalHeading>
          <ModalLine style={{ width: '149px' }} />
          <ModalSubHead variant="h4">Publish info</ModalSubHead>
          <ModalLine style={{ width: '149px' }} />
        </div>}
      centered
      footer={null}
      destroyOnClose
      closable={true}
      width={769}
      style={{ paddingBottom: '40px' }}
    >
      <RadioGroup data-test-id='changePublishType' value={this.state.publishTypeKey} onChange={this.changePublishType}>
        <FormControlLabel
          control={<Radio />}
          value='all_companies'
          labelPlacement="start"
          label={<RadioDiv>
            <img src={All} />
            <div>
              <RadioOptionHead>All</RadioOptionHead>
              <RadioOptionSubHead>The reminder will be sent to all companies “worker”.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
        />
        <FormControlLabel
          control={<Radio />}
          value='specific_companies'
          labelPlacement="start"
          label={<RadioDiv>
            <img src={specific} />
            <div>
              <RadioOptionHead>Specific Companies</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to specific companies “worker”.</RadioOptionSubHead>
              <RadioOptionSubHead>PetroPal, PetroPal1 and 3 others</RadioOptionSubHead>
            </div>
          </RadioDiv>}
        />
        <FormControlLabel
          control={<Radio />}
          value='all_groups'
          labelPlacement="start"
          label={<RadioDiv>
            <img src={group} />
            <div>
              <RadioOptionHead>All Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to all groups of all worker.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
        />
        <FormControlLabel
          control={<Radio />}
          value='specific_groups'
          labelPlacement="start"
          label={<RadioDiv>
            <img src={group} />
            <div>
              <RadioOptionHead>Specific Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to specific groups.</RadioOptionSubHead>
              <RadioOptionSubHead>Ibrahim, Ibrahim1 and 3 others</RadioOptionSubHead>
            </div>
          </RadioDiv>}
        />
      </RadioGroup>
      <BackButton data-test-id="closeModalButton" onClick={() => { this.setState({ publishTypeModal: false, publishTypeKey: "" }) }}>Back</BackButton>
      <NextButton
        data-test-id="addPublishTypeButton"
        style={{ width: '65%', marginLeft: '10px' }}
        onClick={() => { this.setState({ publishTypeModal: false }) }}
        disabled={!this.state.publishTypeKey}
      >
        Send
      </NextButton>
    </FieldModal>
  )

  apiSuccessModal = () => (
    <FieldModal
      data-test-id="successModal"
      open={this.state.apiSuccessModal}
      onCancel={this.closePublishTypeModal}
      closable={true}
      centered
      destroyOnClose
      footer={null}
      style={{ height: '430px' }}
      width={696}
    >
      <div style={{ display: 'flex', justifyContent: "center", padding: '20px', flexDirection: 'column', alignItems: 'center' }}>
        <img src={this.state.themeStatus ? darkVerify : lightVerify} alt="verificationModal" style={{ height: '176px' }} />
        <SuccessMessage style={{ textAlign: 'center' }}>Your post has been {this.state.successMessage} Successfully</SuccessMessage>
      </div>
      <NextButton
        data-test-id="confirmModalButton"
        fullWidth
        onClick={this.closePublishTypeModal}
        style={{ color: '#F8FAFC' }}
      >
        Done
      </NextButton>
    </FieldModal>
  )

  deleteModal = () => (
    <FieldModal
      onCancel={this.closeBulletinModal}
      open={this.state.deleteModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h3">Delete</ModalHeading>
          <ModalLine style={{ width: '101px' }} />
          <ModalSubHead variant="subtitle1" style={{ maxWidth: '125px', overflowX: "auto" }}>Delete Post</ModalSubHead>
          <ModalLine style={{ width: '101px' }} />
        </div>}
      centered
      footer={null}
      closable={false}
      width={611}
      destroyOnClose
      style={{ height: '356px', padding: '40px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <img src={alarmImage} style={{ width: "111px", height: "111px", marginRight: '10px' }} />
        <div><ModalSubHead variant="body1">Deleting this post will cause your content to be lost. Are you sure</ModalSubHead>
          <ModalSubHead variant="body1">you want to delete?</ModalSubHead>
        </div>
      </div>
      <BackButton data-test-id="closeDeleteModal" onClick={this.closeBulletinModal}>Cancel</BackButton>
      <NextButton data-test-id='deleteBulletin' onClick={this.deleteBulletinApi} style={{ width: '65%', color: '#F8FAFC', marginLeft: '10px' }}>Delete</NextButton>
    </FieldModal>
  )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.state.themeStatus ? darkTheme : lightTheme}>
        <Loader loading={this.state.isLoading} />
        <HamburgerMenu
          navigation={this.props.navigation}
          handleMenu={this.toggleMenu}
          open={this.state.menuStatus}
          themeDark={this.state.themeStatus}
          handleTheme={this.toggleTheme}
        />
        <MainContainer style={{ paddingLeft: this.state.menuStatus ? 273 : 120 }}>
          <HeadDiv >
            <BodyHeader>
              <div>
                <BulletinTitle>Bulletin Board</BulletinTitle>
                <BulletinSubTitle>see what’s new today!</BulletinSubTitle>
              </div>
              <AddModuleButton
                data-test-id="addBulletin"
                endIcon={<AddBoxOutlinedIcon />}
                onClick={this.openBulletinModal}
              >
                Add Bulletin Board
              </AddModuleButton>
            </BodyHeader>
            <Grid container>
              <Grid item xs={8}>
                {this.state.bulletinList?.map((item) => (
                  <BulletinCard key={item.id}>
                    <CardTitle></CardTitle>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <BulletinDescription>{item.attributes.description}</BulletinDescription>
                      <DotSection>
                        <IconButton
                          aria-controls="menu-appbar"
                          aria-label="account of current user"
                          aria-haspopup="true"
                          onClick={(event) => this.OpenNavMenu(event, item.attributes.id)}
                          data-test-id="openOption"
                          color="inherit"
                        >
                          <MoreVertOutlined />
                        </IconButton>
                        <Menu
                          anchorEl={this.state.anchorElNav}
                          id="menu-appbar"
                          anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom',
                          }}
                          keepMounted
                          transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                          }}
                          onClose={this.closeNavMenu}
                          open={Boolean(this.state.anchorElNav)}
                          style={{ top: 30 }}
                          elevation={0}
                        >
                          <MenuItem data-test-id='editPost'
                            onClick={this.editBulletin}
                          >
                            <img height={16} width={16} src={editIcon} style={{ marginRight: 10 }} />
                            <Typography style={{ fontSize: 12, color: '#1A469C' }}>Edit Post</Typography>
                          </MenuItem>

                          <MenuItem data-test-id='deletePost'
                            onClick={this.deleteBulletin}
                          >
                            <img height={16} width={16} src={deleteIcon} style={{ marginRight: 10 }} />
                            <Typography style={{ fontSize: 12, color: '#DC2626' }}>Delete Post</Typography>
                          </MenuItem>
                        </Menu>
                      </DotSection>
                    </div>
                  </BulletinCard>
                ))}
              </Grid>
              <Grid item xs={4}>
              </Grid>
            </Grid>
            <Pagination
              count={this.state.totalPage}
              data-test-id="changePageNumber"
              onChange={this.changePageNumber}
              page={this.state.currentPage}
              size="small"
            />
          </HeadDiv>
          {this.BulletinModal()}
          {this.publishTypeModal()}
          {this.apiSuccessModal()}
          {this.deleteModal()}
          <ToastContainer containerId={'A'} />
        </MainContainer>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BulletinTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  lineHeight: '40px',
  fontSize: '30px',
  color: theme.palette.info.main
}))

const BulletinSubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: '14px',
  fontSize: '14px',
  letterSpacing: '0.41px',
  color: theme.palette.info.main
}))

const BodyHeader = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  margin: '10px'
})

const AddModuleButton = styled(Button)(({ theme }) => ({
  width: '100%',
  maxWidth: '208px',
  height: '44px',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  padding: '10px 16px',
  textTransform: 'capitalize',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.info.contrastText,
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  }
}))

const BulletinCard = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: '12px 16px',
  borderRadius: '6px',
  margin: '5px',
  borderLeft: `4px solid ${theme.palette.info.contrastText}`
}))

const CardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  lineHeight: '16px',
  fontSize: '24px',
  color: theme.palette.info.main
}))

const BulletinDescription = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: '14px',
  fontSize: '14px',
  color: theme.palette.info.main
}))

const MenuTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  marginLeft: '10px'
}))

const StatusButton = styled(Button)(({ theme }) => ({
  height: '33.94px',
  width: '100%',
  padding: '7.97px 15.94px',
  borderRadius: '39.86px',
  background: theme.palette.text.hint,
  color: theme.palette.action.selected,
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '18px',
  maxWidth: '125px',
  marginTop: '12px',
  marginRight: '15px'
}))

const SaveButton = styled(Button)(({ theme }) => ({
  height: '44px',
  width: '100%',
  padding: '10px 16px',
  borderRadius: '30px',
  background: theme.palette.info.contrastText,
  color: '#F8FAFC',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  maxWidth: '70px',
  textTransform: 'lowercase'
}))

const AttachmentDiv = styled(Box)(({ theme }) => ({
  height: '64',
  display: 'flex',
  padding: '15px 0px',
  marginBottom: `1px solid ${theme.palette.action.disabled}`
}))

const DescriptionField = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  minHeight: '192px',
  gap: '8px',
  borderRadius: '8px',
  padding: '10px 12px',
  border: `1px solid ${theme.palette.info.dark}`,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '25px',
  textAlign: 'left',
  color: theme.palette.info.main,
  marginTop: '5px',
  background: theme.palette.background.default,
}))

const SuccessMessage = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '40px',
  textAlign: 'left',
  color: theme.palette.info.main,
}))

export const PublishDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  minWidth: '91.44px',
  height: '38px',
  padding: '9.5px 12.67px',
  borderRadius: '6.33px',
  border: `0.79px solid ${theme.palette.info.dark}`
}))
// Customizable Area End

// Customizable Area End
