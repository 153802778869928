// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getItemsAPiEndPoint = "/bx_block_bulletinboard2/items_at_date/";
exports.mainAPiEndPoint = "bx_block_bulletinboard2/items/";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BulletinBoard";
exports.labelBodyText = "BulletinBoard Body";
exports.errorLable = "Error!";
exports.GET = "GET";
exports.POST = "POST";
exports.DELETE = "DELETE";
exports.PUT = "PUT";
exports.saveChangesTitle = "Save Changes";
exports.descriptionText = "Description:";
exports.placeholderText = "Add Text Here"
exports.addNewItemTitle = "Add New Item"
exports.noDataText = "No Items Available!";
exports.formDataAPIType = "multipart/form-data";
exports.listOfMonths= [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
exports.createBulletinEndPoint = "bx_block_posts/posts"
// Customizable Area End
