// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const mailIcon = require("../assets/mailIcon.svg");
export const bellIcon = require("../assets/bellIcon.svg");
export const profileImage = require("../assets/profilePic.svg");
export const addIcon = require("../assets/addIcon.png");
export const attachIcon = require("../assets/attachIcon.png");
export const attachIconDark = require("../assets/attachIconDark.png");
export const atIcon = require("../assets/atIcon.png");
export const atIconDark = require("../assets/atIconDark.png");
export const cloudIcon = require("../assets/cloudIcon.png");
export const cloudIconDark = require("../assets/cloudIconDark.png");
export const videoIcon = require("../assets/videoIcon.png");
export const videoIconDark = require("../assets/videoIconDark.png");
export const closeIcon = require("../assets/closeIcon.png");
export const greenDot = require("../assets/greenDotIcon.png");
export const orangeDot = require("../assets/orangeDotIcon.png");
export const viewAll = require("../assets/viewAllIcon.png");
export const addSqureIcon = require("../assets/addSquareIcon.png");
export const moreIcon = require("../assets/moreIcon.png");
export const trashIcon = require("../assets/trashIcon.png");
export const editIcon = require("../assets/pencilIcon.png");
// Customizable Area End
