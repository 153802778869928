import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, {createRef} from "react";
interface Errors {
  nameError:string;
  emailError:string;
  subjectError:string;
  messageError:string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading:boolean;
  darkMode:boolean;
  name:string;
  email:string;
  subject:string;
  message:string;
  error:Errors;
  openSnackbar:boolean;
  isSubmitted:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendContactMessageAPIId:string;
  contactRef: React.RefObject<HTMLDivElement>;
  topRef:React.RefObject<HTMLDivElement>;
  emailReg: RegExp;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.contactRef = createRef<HTMLDivElement>();
    this.topRef = createRef<HTMLDivElement>();
    this.emailReg = new RegExp(configJSON.emailReg);
    this.subScribedMessages = [
    ];

    this.state = {
      loading:false,
      darkMode:false,
      name:"",
      email:"",
      subject:"",
      message:"",
      error:{
        nameError:"",
        emailError:"",
        subjectError:"",
        messageError:"",
      },
      isSubmitted:false,
      openSnackbar:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.sendContactMessageAPIId) {
          this.setState({
            name:" ",
            email:" ",
            subject:" ",
            message:" ",
            isSubmitted:true,
            openSnackbar:true,
            error:{
              nameError:"",
              emailError:"",
              subjectError:"",
              messageError:""
              }
        })
        }
          else {
            this.setState({ loading: false })
            this.parseApiErrorResponse(responseJson);
          }
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToLoginPage=()=> {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToContactForm=()=>{
    if(this.contactRef.current)
      this.contactRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  goToTopSection=()=>{
    if(this.topRef.current)
      this.topRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  handleThemeChange=()=>{
    this.setState({
      darkMode:!this.state.darkMode,
    })
  }

  validateForm = () => {
    const { name, email, subject, message } = this.state;
    let error={
      nameError:"",
      emailError:"",
      subjectError:"",
      messageError:"",
    };
    let formIsValid = true;

    if (!name) {
      formIsValid = false;
      error['nameError'] = 'Please enter your name.';
    }

    if (!email) {
      formIsValid = false;
      error['emailError'] = 'Please enter your email.';
    } else if (!this.emailReg.test(email.trim())) {
      console.log(this.emailReg.test(email),email)
      formIsValid = false;
      error['emailError'] = 'Email is not valid.';
    }

    if (!subject) {
      formIsValid = false;
      error['subjectError'] = 'Please enter a subject.';
    }

    if (!message) {
      formIsValid = false;
      error['messageError'] = 'Please enter your message.';
    }

    this.setState({ error });
    return formIsValid;
  };
  handleEmailChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({
      email:event.target.value
    })
  }
  handleNameChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({
      name:event.target.value
    })
  }
  handleSubjectChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({
      subject:event.target.value
    })
  }
  handleMessageChange=(event: React.ChangeEvent<HTMLTextAreaElement>)=>{
    this.setState({
      message:event.target.value
    })
  }
  submitContactForm=()=>{
    if(this.validateForm()){
      this.sendContactMessage();
      this.setState({
        isSubmitted:true,
        openSnackbar:true,
        error:{
          nameError:"",
          emailError:"",
          subjectError:"",
          messageError:""
          },
          name:"",
          email:"",
          subject:"",
          message:""
      })  
    }
  }
  handleCloseSnackbar=()=>{
    this.setState({
      isSubmitted:false,
      openSnackbar:false,
    })
  }
  sendContactMessage=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const attrs = {
      'name': this.state.name,
      'subject': this.state.subject,
      'email': this.state.email,
      'message': this.state.message,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendContactMessageAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactPOSTAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({contact_us:attrs})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
