import React from "react";

import {
  // Customizable Area Start
  Box,
  Grid,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
  DialogContent,
  Button,
  Dialog,
  Typography, Switch,
  Tabs, Tab
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UpdateIcon from '@material-ui/icons/Update';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import CloseIcon from "@material-ui/icons/Close";
import { styled,CSSProperties } from "@material-ui/styles";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { profileImg, sampleImg } from "./assets";


function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// Customizable Area End

import AutomaticremindersController, {
  Props,
  EventObjects,
} from "./AutomaticremindersController.web";

export default class Automaticreminders extends AutomaticremindersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start


      <Box style={{ paddingLeft: 107, paddingRight: 48 }}>
        <Box style={{ maxWidth: 1070, width: "100%", backgroundColor: "#F9F9F9", borderRadius: 18, marginTop: 20, marginBottom: 20 }}>
          <Box style={{ padding: "26px 52px 26px 39px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px" }}>
            <Box>
              <Typography style={{ fontWeight: 500, fontSize: "30px", lineHeight: "40px", color: "#0F172A", fontFamily: "Rubik" }}>
                Reminders
              </Typography>
              <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "14px", color: "#0F172A", letterSpacing: 0.41, fontFamily: "Rubik" }}>
                Select when and how to be notified
              </Typography>
            </Box>
            <Box style={{ display: 'flex', gap: 26, alignItems: 'center' }}>
              <Box>
                <SwitchTheme style={{ display: 'flex',alignItems: 'center', gap: 1, height: 24,flexWrap: 'nowrap' }}>
                  <Switch
                    onChange={this.toggleButton}
                    data-test-id="toggle-field1"
                    checked={this.state.togglePush}
                  />
                  <Typography style={{  fontWeight: 400,fontFamily: "Rubik",color: '#475569', lineHeight: "10px", fontSize: 16}}>Push</Typography>
                </SwitchTheme>
                <Box>
                  <SwitchTheme style={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'nowrap', height: 24 }}>
                    <Switch
                      onChange={this.toggledButton}
                      checked={this.state.toggleEmail}
                      data-test-id="toggle-field"
                    />
                    <Typography style={{ color: '#475569', fontWeight: 400, fontSize: 16, lineHeight: "10px", fontFamily: "Rubik" }}>Email</Typography>
                  </SwitchTheme>
                </Box>
              </Box>
              <Box>
                <Box style={{ display: 'flex', alignItems: 'center', gap: 26,}}>
                  <Typography style={{ color: "#334155" , fontSize: 12,fontWeight: 400, fontFamily: "Rubik"}}>Schedule Summary</Typography>
                  <Typography style={{ fontWeight: 400, fontSize: 12, fontFamily: "Rubik", color: "#334155" }}>CT time zone</Typography>
                </Box>
                <Box style={{ gap: 16, display: 'flex', alignItems: 'center' }}>
                  <Typography style={{ borderRadius: 8, border: '1px solid gray', padding: '5px 15px', color: "#0F172A", lineHeight: 1, fontWeight: 400, fontFamily: "Rubik", fontSize: 16 }}>01/12/2024</Typography>
                  <Typography style={{ padding: '5px 15px', borderRadius: 8, lineHeight: 1, border: '1px solid gray' }}>01:30 AM</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box style={{ maxWidth: 1070, width: "100%", backgroundColor: "white", borderRadius: 18, marginBottom: 20 }}>
          <Box style={{ maxWidth: 1035, borderRadius: "16.5px 16.5px 90px 16.5px", backgroundColor: '#F9F9F9' }}>
            <Box style={{ padding: '31px 40px 40px' }}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',gap: 10 }}>
                <CustomTabs value={this.state.value} onChange={this.handleTabs} aria-label="simple tabs example">
                  <Tab label="All" style={customStyle} {...a11yProps(0)} data-test-id="tabs_click"/>
                  <Tab label="Unread" style={customStyle} {...a11yProps(1)} />
                  <Tab label="Completed" style={customStyle} {...a11yProps(2)} />
                </CustomTabs>

                <Box style={{ maxWidth: 272, width: '100%', display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'space-between' }}>
                  <ChevronRightIcon style={{ transform: 'rotate(180deg)', color: 'gray' }} />
                  <Typography style={{ display: 'flex', alignItems: 'center', gap: 10 }}>June 26,2024 <CalendarTodayIcon style={{ color: 'blue' }} /></Typography>
                  <ChevronRightIcon style={{ color: 'gray' }} />
                </Box>
              </Box>
              <TabPanel value={this.state.value} index={0}>
                <Box>
                  <Box style={{ marginTop: 13, display: 'flex', gap: 20, width: '100%' }}>
                    <Box style={{ background: 'white', padding: '12px 24px', borderRadius: 15, width: '100%', maxWidth: 654 }}>
                      <Typography style={{ position: 'relative', fontSize: 8.2, color: '#475569' }}> <span style={{ display: 'inline-block', width: 7, height: 7, background: '#DC2626', borderRadius: '50%', position: 'absolute', left: -10, top: 2 }}></span> Reminder Title</Typography>
                      <Box style={{  flexWrap: 'nowrap',display: 'flex', gap: 10, width: '100%', alignItems: 'center', marginBottom: 12, justifyContent: 'space-between' }}>
                        <Typography style={webStyle.title}>You are making a good progress, Let Finish that with great certificate</Typography>
                        <Typography style={{alignItems: 'center', display: 'flex' }}><span style={{ minWidth: 'max-content',fontSize: 10,color: "#475569", fontWeight: 400}}>06/26/2024 <span>1:45 PM</span></span> <MoreVertIcon style={{ color: 'gray' }} /></Typography>
                      </Box>
                      <Box style={{ display: 'flex', border: '1px solid gray', alignItems: 'center', gap: 15, borderRadius: 15 }}>
                        <Box style={{ flex: 1 }}>
                          <img  alt="" style={{  height: 91, borderRadius: 15, width: '100%', objectFit: 'cover' }} src={sampleImg}/>
                        </Box>
                        <Box style={{  position: 'relative' ,flex: 1}}>
                          <Box style={{  height: 25, width: 25, border: '1px solid blue', borderRadius: '50%', position: 'absolute', top: 10, right: 10 }}><ChevronRightIcon style={{ color: 'blue' }} /></Box>
                          <Typography style={{ fontFamily: "Rubik", fontWeight: 400, fontSize: 10, color: "#475569"}}>Course Name</Typography>
                          <Typography style={{ fontSize: 12, fontFamily: "Rubik", fontWeight: 400, color: "#0F172A"}}>UI/UX Principles</Typography>
                          <Typography style={{ fontSize: 10, color: "#475569", fontWeight: 400, fontFamily: "Rubik" }}>Lessons</Typography>
                          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ fontWeight: 400,fontFamily: "Rubik", fontSize: 12, color: "#0F172A"}}>7/16</Typography>
                            <Typography style={{ fontSize: 12,  fontWeight: 400,color: "#0F172A", fontFamily: "Rubik" }}>70%</Typography>
                          </Box>
                          <Box style={{ height: 4, borderRadius: 8, background: 'gray', width: '90%', overflow: 'hidden'}}><Box style={{ height: 3, background: 'blue', borderRadius: 8, width: '60%' }}></Box></Box>
                        </Box>
                      </Box>
                      <Box style={{ display: 'flex', justifyContent: 'space-between', gap: 10, marginTop: 12 }}>
                        <Box style={{ display: 'flex',gap: 10, alignItems: 'center' }}>
                          <img src={profileImg} style={{ borderRadius: '50%', width: 40, height: 40}} alt="" />
                          <Box>
                            <Typography style={{ fontWeight: 700, fontFamily: "Rubik" ,fontSize: 12}}>Ibrahim M.</Typography>
                            <Typography style={{ fontWeight: 500, fontSize: 12, color: "#848484",fontFamily: "Rubik"}}>Instructor</Typography>
                          </Box>
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'flex-end', gap: 10}}>
                          <Box style={{ padding: '7px 14px', color: '#DC2626', borderRadius: 32, fontSize: 10, fontWeight: 500,backgroundColor: '#FEE2E2' }} data-test-id="set_reminder">2ND TIME REMINDER</Box>
                          <Box>
                            <Typography style={{ fontWeight: 400, color: "#848484", fontSize: 9 }}>Due Date</Typography>
                            <Box style={{ backgroundColor: '#D1FAE5', padding: '7px 12px', borderRadius: 32, display: 'flex',alignItems: 'center', gap: 8, color: '#059669', fontSize: 10, fontWeight: 500 }}><UpdateIcon style={{ fontSize: 14 }} /> 1 WEEK LEFT</Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box style={{ background: 'white', borderRadius: 15, padding: '12px 24px', maxWidth: 281, width: '100%' }}>
                      <Typography style={{ position: 'relative',color: '#475569' , fontSize: 8.2}}> <span style={{ display: 'inline-block', width: 7, background: '#DC2626', height: 7, borderRadius: '50%', position: 'absolute', left: -10, top: 2 }}></span> Reminder Title</Typography>
                      <Box style={{ display: 'flex', flexWrap: 'nowrap', width: '100%', gap: 10, alignItems: 'center', marginBottom: 12 , justifyContent: 'space-between'}}>
                        <Typography style={{ fontWeight: 700, color: "#0F172A", fontSize: 10 }}>You are making a good progress, Let Finish that with great certificate</Typography>
                        <Typography style={{ alignItems: 'center',display: 'flex' }}><MoreVertIcon style={{ color: 'gray' }} /></Typography>
                      </Box>
                      <Box style={{ borderRadius: 15, padding: 12, border: '1px solid gray' }}>
                        <Box style={{ position: 'relative', paddingRight: 30 }}>
                          <Box style={{ width: 25, height: 25, position: 'absolute', borderRadius: '50%' ,padding: 4, border: '1px solid blue', right: 0, bottom: 0 }}><SmsOutlinedIcon style={{ color: 'blue', fontSize: 16 }} /></Box>
                          <Typography style={{ fontSize: 8.22, fontWeight: 400, color: "#475569", fontFamily: "Rubik" }}>Reminder Message</Typography>
                          <Typography style={{ fontSize: 9, color: "#0F172A", fontWeight: 400 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exe</Typography>
                        </Box>
                      </Box>
                      <Box style={{ display: 'flex', gap: 10, marginTop: 12 , justifyContent: 'space-between'}}>
                        <Box style={{ display: 'flex', alignItems: 'center', gap: 10}}>
                          <img  style={{ width: 40, borderRadius: '50%' , height: 40}} src={profileImg} alt="" />
                          <Box>
                            <Typography style={{  fontFamily: "Rubik" ,fontWeight: 700, fontSize: 12}}>Ibrahim M.</Typography>
                            <Typography style={{ fontWeight: 500, fontSize: 12, fontFamily: "Rubik", color: "#848484" }}>Instructor</Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography style={{ color: "#848484",fontSize: 9, fontWeight: 400 }}>Due Date</Typography>
                          <Box style={{ backgroundColor: '#FEF3C7', borderRadius: 32, display: 'flex', padding: '7px 12px', gap: 8, color: '#D97706', alignItems: 'center', fontSize: 9.87 , fontWeight: 500}}><UpdateIcon style={{ fontSize: 14 }} /> 5 DAYS LEFT</Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  
                  <Box style={{ marginTop: 13, display: 'flex', gap: 20, width: '100%' }}>
                    <Box style={{ background: 'white', padding: '12px 24px', borderRadius: 15, width: '100%', maxWidth: 281 }}>
                      <Typography style={{ position: 'relative', fontSize: 8.2, color: '#475569' }}> <span style={{ display: 'inline-block', width: 7, height: 7, background: '#DC2626', borderRadius: '50%', position: 'absolute', left: -10, top: 2 }}></span> Reminder Title</Typography>
                      <Box style={{ display: 'flex', flexWrap: 'nowrap', width: '100%', gap: 10, alignItems: 'center', justifyContent: 'space-between', marginBottom: 12 }}>
                        <Typography style={{ color: "#0F172A", fontWeight: 700, fontSize: 10 }}>You are making a good progress, Let Finish that with great certificate</Typography>
                        <Typography style={{ display: 'flex', alignItems: 'center' }}><MoreVertIcon style={{ color: 'gray' }} /></Typography>
                      </Box>
                      <Box style={{ borderRadius: 15, border: '1px solid gray', padding: 12 }}>
                        <Box style={{ position: 'relative', paddingRight: 30 }}>
                          <Box style={{ width: 25, height: 25, padding: 4, borderRadius: '50%', border: '1px solid blue', position: 'absolute', right: 0, bottom: 0 }}><SmsOutlinedIcon style={{ color: 'blue', fontSize: 16 }} /></Box>
                          <Typography style={{ fontSize: 8.22, color: "#475569", fontWeight: 400, fontFamily: "Rubik" }}>Reminder Message</Typography>
                          <Typography style={{ fontSize: 9, fontWeight: 400, color: "#0F172A" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exe</Typography>
                        </Box>
                      </Box>
                      <Box style={{ display: 'flex', gap: 10, justifyContent: 'space-between', marginTop: 12 }}>
                        <Box style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                          <img src={profileImg} style={{ width: 40, height: 40, borderRadius: '50%' }} alt="" />
                          <Box>
                            <Typography style={{ fontWeight: 700, fontSize: 12, fontFamily: "Rubik" }}>Ibrahim M.</Typography>
                            <Typography style={{ fontWeight: 500, fontSize: 12, fontFamily: "Rubik", color: "#848484" }}>Instructor</Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography style={{ color: "#848484", fontWeight: 400, fontSize: 9 }}>Due Date</Typography>
                          <Box style={{ backgroundColor: '#FEF3C7', borderRadius: 32, padding: '7px 12px', display: 'flex', gap: 8, alignItems: 'center', color: '#D97706', fontWeight: 500, fontSize: 9.87 }}><UpdateIcon style={{ fontSize: 14 }} /> 5 DAYS LEFT</Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box style={{ background: 'white', borderRadius: 15, padding: '12px 24px', width: '100%', maxWidth: 654 }}>
                      <Typography style={{ position: 'relative', borderRadius: 15, fontSize: 8.2 }}> <span style={{ display: 'inline-block', width: 7, height: 7, background: '#DC2626', borderRadius: '50%', position: 'absolute', left: -10, top: 2 }}></span> Reminder Title</Typography>
                      <Box style={{ display: 'flex', flexWrap: 'nowrap', width: '100%', gap: 10, alignItems: 'center', justifyContent: 'space-between', marginBottom: 12 }}>
                        <Typography style={webStyle.title}>You are making a good progress, Let Finish that with great certificate</Typography>
                        <Typography style={{ display: 'flex', alignItems: 'center' }}><span style={{
                          fontSize: 10, minWidth: 'max-content', fontWeight
                            : 400, color: "#475569"
                        }}>06/26/2024 <span>1:45 PM</span></span> <MoreVertIcon style={{ color: 'gray' }} /></Typography>
                      </Box>
                      <Box style={{ display: 'flex', gap: 15, alignItems: 'center', borderRadius: 15, border: '1px solid gray' }}>
                        <Box style={{ flex: 1 }}>
                          <img src={sampleImg} alt="" style={{ borderRadius: 15, height: 91, objectFit: 'cover', width: '100%' }} />
                        </Box>
                        <Box style={{ flex: 1, position: 'relative' }}>
                          <Box style={{ width: 25, height: 25, borderRadius: '50%', border: '1px solid blue', position: 'absolute', right: 10, top: 10 }}><ChevronRightIcon style={{ color: 'blue' }} /></Box>
                          <Typography style={{ fontWeight: 400, fontSize: 10, color: "#475569", fontFamily: "Rubik" }}>Course Name</Typography>
                          <Typography style={{ fontWeight: 400, fontSize: 12, color: "#0F172A", fontFamily: "Rubik" }}>UI/UX Principles</Typography>
                          <Typography style={{ fontWeight: 400, fontSize: 10, color: "#475569", fontFamily: "Rubik" }}>Lessons</Typography>
                          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ fontWeight: 400, fontSize: 12, color: "#0F172A", fontFamily: "Rubik" }}>7/16</Typography>
                            <Typography style={{ fontWeight: 400, fontSize: 12, color: "#0F172A", fontFamily: "Rubik" }}>70%</Typography>
                          </Box>
                          <Box style={{ height: 4, background: 'gray', borderRadius: 8, overflow: 'hidden', width: '90%' }}><Box style={{ height: 3, background: 'blue', borderRadius: 8, width: '60%' }}></Box></Box>
                        </Box>
                      </Box>
                      <Box style={{ display: 'flex', gap: 10, justifyContent: 'space-between', marginTop: 12 }}>
                        <Box style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                          <img  alt="" src={profileImg} style={{ width: 40, borderRadius: '50%', height: 40 }} />
                          <Box>
                            <Typography style={{ fontSize: 12, fontWeight: 700,fontFamily: "Rubik" }}>Ibrahim M.</Typography>
                            <Typography style={{ fontWeight: 500, fontFamily: "Rubik", fontSize: 12, color: "#848484" }}>Instructor</Typography>
                          </Box>
                        </Box>
                        <Box style={{ display: 'flex', gap: 10, alignItems: 'flex-end' }}>
                          <Button data-test-id="closeBtn" style={{ padding: '7px 14px', color: '#DC2626', borderRadius: 32, backgroundColor: '#FEE2E2', fontWeight: 500, fontSize: 10 }}>2ND TIME REMINDER</Button>
                          <Box>
                            <Typography style={{ color: "#848484", fontWeight: 400, fontSize: 9 }}>Due Date</Typography>
                            <Box style={{ backgroundColor: '#D1FAE5', borderRadius: 32, padding: '7px 14px', display: 'flex', gap: 8, alignItems: 'center', color: '#059669', fontSize: 10, fontWeight: 500 }}><UpdateIcon style={{ fontSize: 14 }} /> 1 WEEK LEFT</Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel value={this.state.value} index={1}>
              <Box>
                 <Typography>Page change</Typography>
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyle: CSSProperties = {
  fontWeight: 400,
  fontSize: '16px',
  minHeight: '50px',
  minWidth: '100px',
  fontFamily: 'Arial, sans-serif',
  textTransform: 'uppercase',
  "& .MuiTabs-indicator": {
      backgroundColor: 'blue',
  },
};

const SwitchTheme = styled(Box)({
  "& .MuiSwitch-thumb": {
    backgroundColor: 'white',
    height: 16,
    width: 16,
    position: 'relative',
    top: 4,
    left: 4,
    boxShadow: 'none'
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    left: 'unset'
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: 'blue !important',
    opacity: 1
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#E2E8F0',
    opacity: 1,
    height: '18px',
    borderRadius: '15px',
  },
  '& .MuiSwitch-root': {
    height: 46
  }
})

const CustomTabs = styled(Tabs)({
  display: 'flex',
   alignItems: 'center', 
   backgroundColor: 'white',
   maxWidth: 'fit-content',
  padding: '4px',
  justifyContent: 'center', borderRadius: 50,
  '& .MuiTab-textColorInherit.Mui-selected': {
    background: '#EDF1F6',
    color: '#1A469C',
    borderRadius: 50
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  },
})

const CompleteButton = styled(Button)({
  textTransform: "none",
  backgroundColor: "rgba(14,146,69,0.2)",
  color: "rgba(14,146,69,1)",
  border: "1px solid rgba(14,146,69,1)",
  fontSize: "16px",
  padding: "7px 21px",
  width: "100%",
  borderRadius: "10px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(14,146,69,0.5)",
  },
});

const AcceptButton = styled(Button)({
  color: "rgba(21,135,247, 1)",
  border: "1px solid rgba(21,135,247, 1)",
  cursor: "pointer",
  fontSize: "16px",
  borderRadius: "10px",
  textTransform: "none",
  width: "100%",
  padding: "7px 21px",
  backgroundColor: "rgba(21,135,247, 0.2)",
  "&:hover": {
    backgroundColor: "rgba(21,135,247, 0.5)",
  },
});

const webStyle = {
  title: {
    fontWeight: "bold",
    color: "#0F172A",
    fontSize: "12"
  },
  label: {
    fontWeight: 600,
    fontSize: "16px",
    color: "#000",
  },
  closeButton: {
    cursor: "pointer",
    color: "#787878",
    fontSize: "25px",
  },
  modalBody: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    padding: "10px 20px",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 20px",
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: "15px 0px",
    alignItems: "center",
  },
  row: {
    marginBottom: "5px",
  },
  headerStyle: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "20px",
    fontWeight: "bolder",
  },
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    justifyContent: "center",
    display: "flex",
    fontSize: "16px",
    color: "#000",
    alignItems: "center",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    fontSize: "30px",
    marginBottom: "5px",
    color: "#1b3c69",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pending: {
    padding: "5px 10px",
    backgroundColor: "rgba(247,198,0, 0.2)",
    color: "rgba(247,198,0, 1)",
    width: "90px",
    margin: "5px",
    borderRadius: "20px",
    border: "1px solid rgba(247,198,0, 1)",
  },
  ongoing: {
    padding: "5px 10px",
    margin: "5px",
    borderRadius: "20px",
    backgroundColor: "rgba(21,135,247, 0.2)",
    color: "rgba(21,135,247, 1)",
    border: "1px solid rgba(21,135,247, 1)",
    width: "90px",
  },
  completed: {
    padding: "5px 10px",
    margin: "5px",
    borderRadius: "20px",
    backgroundColor: "rgba(14,146,69,0.2)",
    color: "rgba(14,146,69,1)",
    border: "1px solid rgba(14,146,69,1)",
    width: "90px",
  },
  buttonStyle: {
    border: "none",
    padding: "10px 25px",
    cursor: "pointer",
    backgroundColor: "rgb(98, 0, 238)",
    marginLeft: "15px",
    color: "white",
  },
  BreadcrumbsList: {
    display: "flex",
    backgroundColor: "#f8f4f3",
    "& .BreadcrumbsList": {
      color: "grey !important"
    }
  },
  BreadcrumbsItems1: {
    fontWeight: 500,
    fontSize: "14px",
    minHeight: 'unset',
    minWidth: '0px',
    fontFamily:"Rubik",
    textTransform: 'capitalize',
    "& .MuiTabs-indicator": {
      backgroundColor: 'none !important'
    },
  },
};
// Customizable Area End
