import { Message } from "../../../../framework/src/Message"
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../framework/src/RunEngine"

export const apiCall = async (data: any) => {
    const header = {
        'Content-Type': data.contentType,
        // token: data.token
    }
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    )
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        data.method
    )
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        data.endPoint
    )
    data.body && data.type !== "formData" ?
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data.body)
        )
        : requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data.body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
}