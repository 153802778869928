import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  CircularProgress,
  ButtonGroup,
  TextareaAutosize,
  Avatar,
  TextField,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { styled, ThemeProvider } from "@material-ui/core/styles";
import { ExpandMore, ExpandLess, } from "@material-ui/icons";
import { ITask, ISubTask } from "./types";
import { FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { BackButton, NextButton, darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { DesignationField, DotSection, NameField } from "../../notifications/src/Notifications.web";
import { Checkbox } from "antd";
import { InputField } from "../../settings2/src/Settings2.web";
import { deleteIcon, editButton } from './assets'
import { InProgressLabel, AssignedLabel, CompletedLabel } from "../../certificationtracking/src/CertificationTracking.web";
import { ToastContainer } from "react-toastify";
import moment from "moment";
// Customizable Area End

import TaskController, { Props, configJSON } from "./TaskController";

// Customizable Area Start
// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderField = (item: ISubTask, index: number) => (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <TextField
        data-test-id="txtSubTask"
        placeholder="Type here your task Name"
        onChange={(event) => this.changeSubTask(event.target.value, index)}
        value={this.state.subTask[index].name}
      />
      <img data-test-id="removeTextField" src={deleteIcon} onClick={() => this.removeField(index)} />
    </div>
  )

  AddTaskModal = () => (
    <FieldModal
      data-test-id='replyModal'
      open={this.state.isVisibleModal}
      onCancel={this.showAddModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">My Tasks</ModalHeading>
          <ModalLine style={{ width: '25%' }} />
          <ModalSubHead variant="subtitle2">{this.state.editMode ? "Edit " : "Add "}Task</ModalSubHead>
          <ModalLine style={{ width: '25%' }} />
        </div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={742}
      style={{ paddingBottom: '15px', padding: '20px' }}
    >

      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px' }}>
        <Avatar alt="Remy Sharp" src="" />
        <div style={{ marginLeft: '10px' }}>
          <NameField style={{ fontSize: '14px' }}>Ibrahim M.</NameField>
          <DesignationField>Instructor</DesignationField>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', marginLeft: "20px", marginTop: "10px" }}>
        <CardSubTitle>Due Date</CardSubTitle>
        <div style={{ display: 'flex' }}>
          <AssignedLabel className={this.state.taskDueDate === 'within_3_days' ? "selectedOption" : ""}>
            <span
              data-test-id="3dayDue"
              onClick={() => this.setState({ taskDueDate: 'within_3_days' })}
            >
              Within 3 days
            </span>
            {this.state.taskDueDate === 'within_3_days' && <span data-test-id="cancel3days" onClick={() => this.setState({ taskDueDate: '' })}>{" x"}</span>}
          </AssignedLabel>
          <InProgressLabel
            className={this.state.taskDueDate === 'within_5_days' ? "selectedOption" : ""}
            style={{ margin: "0px 10px" }}
          >
            <span
              data-test-id="5dayDue"
              onClick={() => this.setState({ taskDueDate: 'within_5_days' })}
            >
              Within 5 days
            </span>
            {this.state.taskDueDate === 'within_5_days' && <span data-test-id="cancel5days" onClick={() => this.setState({ taskDueDate: '' })}>{" x"}</span>}
          </InProgressLabel>
          <CompletedLabel
            className={this.state.taskDueDate === 'within_1_week' ? "selectedOption" : ""}
          >
            <span
              data-test-id="withinWeekDue"
              onClick={() => this.setState({ taskDueDate: 'within_1_week' })}
            >
              Within 1 week
            </span>
            {this.state.taskDueDate === 'within_1_week' && <span data-test-id='cancel1Week' onClick={() => this.setState({ taskDueDate: '' })}>{" x"}</span>}
          </CompletedLabel>
        </div>
      </div>

      {!this.state.enableSubTask ?
        <>
          <Box sx={{ padding: "10px 10px 0px 20px" }}>
            <Typography
              color="secondary"
              variant="subtitle1"
              component="div"
              style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
            >
              Task Title
            </Typography>
            <InputField
              data-test-id="inputTitle"
              type="text"
              placeholder="Type here your task title"
              fullWidth
              value={this.state.title}
              onChange={(event) => this.handleInputTitle(event.target.value)}
            />
          </Box>
          <Box sx={{ padding: "10px 10px 0px 20px" }}>
            <Typography
              color="secondary"
              variant="subtitle1"
              component="div"
              style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
            >
              Task Description
            </Typography>
            <TaskDescriptionField
              data-test-id="inputDescription"
              maxLength={100}
              onChange={(event) => this.handleInputDescription(event.target.value)}
              placeholder="Type here your task description"
              value={this.state.description}
            />
          </Box>
          <CheckLine style={{ padding: "10px 10px 0px 20px" }}>
            <TermsChecked data-test-id="checkImportant" onChange={this.checkImportant} /><CheckText>Mark this task as an important task</CheckText>
          </CheckLine>
        </>
        :
        <div className="scrollModal">
          {this.state.subTask.map((item, index) => this.renderField(item, index))}
          <AddNewButton data-test-id="btnAddNewTask" variant="text" onClick={this.addSubTask}>Add New Task</AddNewButton>
        </div>
      }
      <BackButton
        onClick={this.showAddModal}
        style={{ marginLeft: '20px' }}
      >
        Cancel
      </BackButton>
      <NextButton
        data-test-id="enableAddSubTask"
        style={{ width: '65%', marginLeft: '10px' }}
        onClick={this.selectOperation}
        disabled={!this.state.title || !this.state.description || !this.state.taskDueDate}
      >
        {this.getButtonText()}
      </NextButton>
      <ToastContainer />
    </FieldModal>
  )

  getButtonText = () => {
    if (this.state.enableSubTask) {
      if (this.state.editMode) {
        return "Save"
      } else {
        return 'Done,Add Task'
      }
    } else {
      return 'Next'
    }
  }

  selectOperation = () => {
    if (this.state.enableSubTask) {
      if (this.state.editMode) {
        this.editTask(String(this.state.selectedId))
      } else {
        this.addTask()
      }
    } else {
      this.goToSubTask()
    }
  }

  renderCardSection = (item: ITask) => (
    <CardSection>
      <CardHeader>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CardTitle>{item.attributes.title}</CardTitle>
          <CardSubTitle>{item.attributes.description}</CardSubTitle>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <CircularProgress variant="determinate" value={50} />
          <DotSection>
            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              aria-label="account of current user"
              onClick={(event) => this.handleOptionMenu(event, item)}
              data-test-id="openOption"
              color="inherit"
            >
              <MoreVertOutlinedIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={this.state.anchorOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              elevation={0}
              open={Boolean(this.state.anchorOpen)}
              onClose={() => this.setState({ anchorOpen: null })}
              style={{ top: 30 }}
            >
              <MenuItem data-test-id='editTask' onClick={this.showEditModal}>
                <img height={18} width={18} src={editButton} style={{ marginRight: 10 }} />
                <Typography style={{ fontSize: 14, color: '#475569' }}>{configJSON.editTask}</Typography>
              </MenuItem>

              <MenuItem data-test-id='deleteTask' onClick={() => { this.deleteTask(String(item.attributes.id)) }}>
                <img height={18} width={18} src={deleteIcon} style={{ marginRight: 10 }} />
                <Typography style={{ fontSize: 14, color: '#DC2626' }}>{configJSON.deleteTask}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem>
                <Button
                  variant="text"
                  endIcon={<ArrowForwardIosIcon height={12} width={12} />}
                  style={{ fontSize: 14, color: '#1A469C', width: '100%', textTransform: 'capitalize' }}
                  data-test-id='markTask'
                  aria-controls="menu-subOption"
                  aria-haspopup="true"
                  aria-label="Mark Task"
                  color="inherit"
                  onClick={(event) => this.handleSubOptionMenu(event, item.attributes.id)}
                >
                  {configJSON.markAs}
                </Button>
                <Menu
                  id="menu-subOption"
                  anchorEl={this.state.anchorSubOpen}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  elevation={0}
                  open={Boolean(this.state.anchorSubOpen)}
                  onClose={() => this.setState({ anchorSubOpen: null })}
                  style={{ top: 30, left: 50 }}
                  getContentAnchorEl={null}
                >
                  <MenuItem data-test-id='inProgressTask' onClick={() => this.changeStatus(item, 'in_progress')}>
                    <Typography style={{ fontSize: 14, color: '#475569' }}>In Progress</Typography>
                  </MenuItem>
                  <MenuItem data-test-id='importantTask' onClick={() => this.changeStatus(item, 'important')}>
                    <Typography style={{ fontSize: 14, color: '#475569' }}>Important</Typography>
                  </MenuItem>
                  <MenuItem data-test-id='CompletedTask' onClick={() => this.changeStatus(item, 'completed')}>
                    <Typography style={{ fontSize: 14, color: '#475569' }}>Completed</Typography>
                  </MenuItem>
                  <MenuItem data-test-id='CancelledTask' onClick={() => this.changeStatus(item, 'cancelled')}>
                    <Typography style={{ fontSize: 14, color: '#475569' }}>Cancelled</Typography>
                  </MenuItem>
                  <MenuItem data-test-id='InReviewTask' onClick={() => this.changeStatus(item, 'in_review')}>
                    <Typography style={{ fontSize: 14, color: '#475569' }}>In Review</Typography>
                  </MenuItem>
                  <MenuItem data-test-id='OnHoldTask' onClick={() => this.changeStatus(item, 'on_hold')}>
                    <Typography style={{ fontSize: 14, color: '#475569' }}>On Hold</Typography>
                  </MenuItem>
                </Menu>
              </MenuItem>
            </Menu>
          </DotSection>
        </div>
      </CardHeader>
      <CardBody>
        {item.attributes.task_lists?.slice(0, 2)?.map((item: ISubTask) => <CheckLine>
          <TermsChecked /><CheckText>{item.name}</CheckText>
        </CheckLine>)}
        <Accordion expanded={this.state.expanded && (item.attributes.id == this.state.selectedId)}>
          {this.state.expanded && <AccordionDetails>
            <div style={{ display: 'flex', flexDirection: "column" }}>
              {item.attributes.task_lists?.slice(2).map((item: ISubTask) => <CheckLine>
                <TermsChecked /><CheckText>{item.name}</CheckText>
              </CheckLine>)}
            </div>
          </AccordionDetails>}
          <AccordionSummary
            data-test-id="expandAccordion"
            expandIcon={this.state.expanded ? <ExpandLess /> : <ExpandMore />}
          >
            <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", width: '100%' }}>
              <div style={{ display: "flex", flexDirection: 'row' }}>
                <DateText>
                  Due Date
                </DateText>
                {item.attributes.due_date === "within_3_days" && <AssignedLabel>Within 3 Days</AssignedLabel>}
                {item.attributes.due_date === "within_5_days" && <InProgressLabel>Within 5 Days</InProgressLabel>}
                {item.attributes.due_date === "within_1_week" && <CompletedLabel>Within 1 Week</CompletedLabel>}
              </div>
              <DateText>
                {moment(item.attributes.expiration_date).format('MM/DD/YYYY h:mm A')}
              </DateText>
            </div>
          </AccordionSummary>
        </Accordion>
      </CardBody>
    </CardSection>
  )
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.state.selectedTheme ? darkTheme : lightTheme}>
        <HamburgerMenu
          navigation={this.props.navigation}
          handleMenu={this.toggleMenu}
          open={this.state.openMenu}
          themeDark={this.state.selectedTheme}
          handleTheme={this.changeTheme}
        />
        <OuterContainer style={{ paddingLeft: this.state.openMenu ? 273 : 140, paddingRight: 48, transition: 'all 300ms linear' }}>
          <BodyHeader>
            <div>
              <TaskListTitle>My Tasks List</TaskListTitle>
              <TaskListSubTitle>here where you add your tasks and track them</TaskListSubTitle>
            </div>
            <AddTaskButton
              data-test-id="btnAddTaskModal"
              startIcon={<AddBoxOutlinedIcon />}
              onClick={this.showAddModal}
            >
              Add Task
            </AddTaskButton>
          </BodyHeader>
          <Grid container>
            <LeftGrid item xs={"auto"}>
              <ButtonGroup>
                <Button
                  data-test-id='inProgressTask'
                  variant="text"
                  onClick={() => this.changeInprogress(true, false, false, 'in_progress')}
                  className={this.state.inProgressStatus ? "selectedButton" : ""}
                >
                  In Progress
                </Button>
                <Button
                  data-test-id='completedTask'
                  variant="text"
                  onClick={() => this.changeInprogress(false, true, false, 'completed')}
                  className={this.state.completedStatus ? "selectedButton" : ""}
                >
                  Completed
                </Button>
                <Button
                  data-test-id='importantTask'
                  variant="text"
                  onClick={() => this.changeInprogress(false, false, true, 'important')}
                  className={this.state.importantStatus ? "selectedButton" : ""}
                >
                  Important
                </Button>
              </ButtonGroup>
              {this.state.taskList?.map((item) => (
                this.renderCardSection(item)
              ))}
            </LeftGrid>
            <LeftGrid item xs={"auto"}>
              <ButtonGroup>
                <Button
                  data-test-id='inReviewTask'
                  variant="text"
                  onClick={() => this.changeReviewStatus(true, false, false, 'in_review')}
                  className={this.state.inReviewStatus ? "selectedButton" : ""}
                >
                  In Review
                </Button>
                <Button
                  data-test-id='onHoldTask'
                  variant="text"
                  onClick={() => this.changeReviewStatus(false, true, false, 'on_hold')}
                  className={this.state.onHoldStatus ? "selectedButton" : ""}
                >
                  On Hold
                </Button>
                <Button
                  data-test-id='cancelledTask'
                  variant="text"
                  onClick={() => this.changeReviewStatus(false, false, true, 'cancelled')}
                  className={this.state.cancelledStatus ? "selectedButton" : ""}
                >
                  Cancelled
                </Button>
              </ButtonGroup>
              {this.state.taskList1?.map((item) => (
                this.renderCardSection(item)
              ))}
            </LeftGrid>
          </Grid>
          {this.AddTaskModal()}
        </OuterContainer>
        <ToastContainer containerId='B' />
      </ThemeProvider >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const OuterContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%'
}))

const TaskListTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '30px',
  fontSize: '30px',
  color: theme.palette.info.main,
  fontWeight: 500,
}))

const TaskListSubTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '14px',
  fontWeight: 400,
  letterSpacing: '0.41px',
  fontSize: '14px',
  color: theme.palette.info.main
}))

const BodyHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  display: 'flex',
  margin: '10px',
  background: theme.palette.action.focus,
  height: "100px",
  borderRadius: "18px",
  marginBottom: '15px',
  padding: '20px'
}))

const AddTaskButton = styled(Button)(({ theme }) => ({
  width: '100%',
  maxWidth: '135px',
  height: '44px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  borderRadius: '30px',
  textTransform: 'capitalize',
  padding: '10px 16px',
  fontSize: '16px',
  fontWeight: 500,
  color: theme.palette.info.contrastText,
  lineHeight: '24px',
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  }
}))

const CardSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: "100%",
  borderRadius: "6px",
  padding: '8px 12px 8px 16px',
  borderLeft: `4px solid ${theme.palette.action.selected}`,
  marginBottom: "10px",
  background: theme.palette.background.default,
  '& .MuiPaper-root': {
    background: theme.palette.background.default,
  },
  '& .MuiAccordion-root.Mui-expanded': {
    margin: 0
  },
  '& .MuiAccordionDetails-root': {
    padding: 0
  },
  '& .MuiPaper-elevation1': {
    boxShadow: 'none'
  },
  '& .MuiAccordion-root::before': {
    backgroundColor: "transparent"
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  }
}))

const CardHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  "& .MuiCircularProgress-colorPrimary": {
    color: theme.palette.info.contrastText
  }
}))

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: '24px',
  color: theme.palette.secondary.main
}))

const CardSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: '14px',
  color: theme.palette.info.light
}))

const CardBody = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'column'
}))

const CheckText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  marginLeft: '5px',
  marginTop: '4px'
}))

const TermsChecked = styled(Checkbox)(({ theme }) => ({
  "& .ant-checkbox-inner": {
    borderRadius: '6px',
    height: '20px',
    width: '20px',
    top: 1,
    border: `1px solid ${theme.palette.info.light}`,
    background: theme.palette.primary.main
  },
  '& .ant-checkbox-input:hover': {
    borderRadius: '6px',
    borderColor: `${theme.palette.info.main}} !important`
  },
  '& .ant-checkbox-checked .ant-checkbox-inner': {
    background: theme.palette.info.contrastText
  },
  '& .ant-checkbox-checked .ant-checkbox-inner:hover': {
    borderRadius: '6px',
    borderColor: `${theme.palette.info.main}} !important`
  }
}))

const CheckLine = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: "row"
}))

const LeftGrid = styled(Grid)(({ theme }) => ({
  marginLeft: "10px",
  background: theme.palette.action.focus,
  height: '605px',
  width: "48%",
  overflowY: "scroll",
  borderRadius: "18px",
  padding: '20px',
  "& .MuiButtonGroup-root": {
    borderRadius: '50px',
    background: theme.palette.background.default,
    width: "100%",
    marginBottom: '10px'
  },
  "& .MuiButtonGroup-grouped": {
    fontWeight: 500,
    textTransform: 'capitalize',
    lineHeight: '22px',
    fontsize: '14px',
    borderRadius: '30px',
    color: theme.palette.info.light,
    margin: '5px',
    padding: '5px',
    width: "100%"
  },
  "& .selectedButton": {
    background: theme.palette.background.paper,
    color: theme.palette.info.contrastText,
  },
}))

const DateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontFamily: 'Rubik',
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '19px',
  marginTop: '4px',
  marginRight: "10px"
}))

const TaskDescriptionField = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  minHeight: '100px',
  gap: '8px',
  borderRadius: '8px',
  padding: '10px 12px',
  border: `1px solid ${theme.palette.info.dark}`,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
  color: theme.palette.info.main,
  marginTop: '5px',
  background: theme.palette.background.default,
}))

const AddNewButton = styled(Button)(({ theme }) => ({
  height: '22px',
  width: "100px",
  textTransform: "capitalize",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "22px",
  float: "right",
  color: theme.palette.info.contrastText
}))
// Customizable Area End
